import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from 'react-router-dom'
import {useSigninCheck} from 'reactfire'
import ActivityLog from '../layouts/ActivityLogs/ActivityLogs'
import Components from '../views/DeveloperGuide/Components/Components'
import DashboardLayout from '../layouts/Dashboard'
import DeveloperGuideLayout from '../layouts/DeveloperGuide'
import ForgotPassword from '../views/ForgotPassword'
import DeleteAccount from '../views/DeleteAccount'
import MobileSignup from '../views/MobileSignup'
import FormInputs from '../views/DeveloperGuide/FormInputs/FormInputs'
import Home from '../views/Home/Home'
import Login from '../views/Login/Login'
import LoginLayout from '../layouts/Login/Login'
import NewPassword from '../views/NewPassword'
import NotFound from '../views/NotFound/NotFound'
import Overview from '../views/Overview'
import Properties from '../views/Properties'
import RolesPermissions from '../views/RolesPermissions'
import Typography from '../views/DeveloperGuide/Typography/Typography'
import PeopleLayout from '../layouts/People'
import Staff from '../views/People/Staff'
import ResidentsReports from '../views/Reports/PeopleReports/Residents'
import StaffReports from '../views/Reports/PeopleReports/Staff'
import GuestsReports from '../views/Reports/PeopleReports/Guests'
import VendorsReports from '../views/Reports/PeopleReports/Vendors'
import Residents from '../views/People/Residents'
import Guests from '../views/People/Guests'
import Vendors from '../views/People/Vendors'
import ResidentsOverview from '../layouts/ResidentsOverview'
import StaffOverview from '../views/StaffOverview'
import GuestsOverview from '../views/GuestsOverview'
import ActivityLogOverview from '../views/ActivityLogOverview'
import DevicesLayout from '../layouts/Devices/Devices'
import Locks from '../views/Devices/Locks'
import Thermostats from '../views/Devices/Thermostats'
import Reports from '../layouts/Reports'
import ReportsPeople from '../layouts/ReportsPeople'
import PropertyDetails from '../layouts/PropertyDetails'
import Units from '../views/PropertyDetails/Units'
import PropertyOverview from '../views/PropertyDetails/Overview'
import PropertyDetailsStaff from '../views/PropertyDetails/Staff'
import OtherAccess from '../views/PropertyDetails/OtherAccess/OtherAccess'
import MessageSetup from '../views/PropertyDetails/MessageSetup/MessageSetup'
import UnitDetails from '../views/UnitDetails/UnitDetails'
import ExportTableContextProvider from '../components/ExportTable/ExportTableContextProvider'
import VendorOverview from '../views/VendorOverview/VendorOverview'
import VendorUserOverview from '../views/VendorUserOverview/VendorUserOverview'
import GuestsInformation from '../layouts/ResidentsOverview/GuestsInformation'
import AppMetrics from '../layouts/ResidentsOverview/AppMetrics/AppMetrics'
import ResidentOverviewPanel from '../layouts/ResidentsOverview/ResidentOverviewPanel'
import NavigateWithParams from '../components/NavigateWithParams/NavigateWithParams'
import PaymentHistory from '../layouts/ResidentsOverview/PaymentHistory'
import CommonAreaDetails from '../views/CommonAreaDetails/CommonAreaDetails'
import PendingInvite from '../views/People/PendingInvite/PendingInvite'
import Spinner from '../components/Spinner'
import LockActivityLogs from '../views/LockActivityLogs/LockActivityLogs'
import BuildingAccounts from '../views/BuildingAccounts'

const AnonymousRoute = () => {
  const {status, data: signInCheckResult} = useSigninCheck()
  const location = useLocation()

  if (status === 'loading') {
    return <Spinner fullScreen />
  }

  if (signInCheckResult.signedIn && !signInCheckResult.user.isAnonymous) {
    return <Navigate to='/overview' state={{from: location}} replace />
  } else {
    return <Outlet />
  }
}

const ProtectedRoute = ({children}: {children: JSX.Element}) => {
  const {status, data: signInCheckResult} = useSigninCheck()
  const location: any = useLocation()

  if (status === 'loading') {
    return <Spinner fullScreen />
  }

  if (signInCheckResult.signedIn && !signInCheckResult.user.isAnonymous) {
    return children
  } else {
    return <Navigate to='/' state={{from: location}} replace />
  }
}

const Router = () => {
  const {data: signInCheckResult} = useSigninCheck()

  return (
    <ExportTableContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path='' element={<AnonymousRoute />}>
            <Route path='' element={<LoginLayout />}>
              <Route path='' element={<Login />} />
              <Route path='forgot-password' element={<ForgotPassword />} />
              <Route path='mobile-signup' element={<MobileSignup />} />
              {!signInCheckResult?.user?.email && (
                <>
                  <Route path='new-password' element={<NewPassword />} />
                  <Route path='delete-account' element={<DeleteAccount />} />
                </>
              )}
            </Route>
          </Route>

          {process.env.NODE_ENV === 'development' ? (
            <Route path='dev-guide' element={<DeveloperGuideLayout />}>
              <Route path='' element={<Home />} />
              <Route path='typography' element={<Typography />} />
              <Route path='form-inputs' element={<FormInputs />} />
              <Route path='components' element={<Components />} />
            </Route>
          ) : null}

          <Route path='404' element={<NotFound />} />
          <Route path='*' element={<NotFound />} />

          <Route
            path=''
            element={
              <ProtectedRoute>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path='overview'>
              <Route path='' element={<Overview />} />
            </Route>
            <Route path='new-password' element={<NewPassword />} />
            <Route path='delete-account' element={<DeleteAccount />} />

            {/* {signInCheckResult?.user?.email && (
              <Route path='delete-account' element={<DeleteAccount />} />
            )} */}
            <Route path='people'>
              <Route path='' element={<PeopleLayout />}>
                <Route path='' element={<Navigate to={'residents'} />} />
                <Route path='residents' element={<Residents />} />

                <Route path='staff' element={<Staff />} />
                <Route path='admin' element={<Residents />} />
                <Route path='guests' element={<Guests />} />
                <Route path='vendors' element={<Vendors />} />
                <Route path='pending-invite' element={<PendingInvite />} />
              </Route>

              <Route path='residents/:residentId' element={<ResidentsOverview />}>
                <Route
                  path=''
                  element={<NavigateWithParams replace={true} to={'overview'} />}
                />
                <Route path='overview' element={<ResidentOverviewPanel />} />
                <Route path='payment' element={<PaymentHistory />} />
                <Route path='metrics' element={<AppMetrics />} />
                <Route path='guests' element={<GuestsInformation />} />
              </Route>
              <Route path='staff/:staffId' element={<StaffOverview />} />
              <Route path='guests/:personAccessId' element={<GuestsOverview />} />

              <Route path='vendors/:vendorId' element={<VendorOverview />} />
              <Route
                path='vendors/:vendorId/add-staff'
                element={<VendorUserOverview />}
              />
              <Route
                path='vendors/:vendorId/user/:userId'
                element={<VendorUserOverview />}
              />
            </Route>

            <Route path='roles-and-permissions'>
              <Route path='' element={<RolesPermissions />} />
            </Route>

            <Route path='devices'>
              <Route path='' element={<DevicesLayout />}>
                <Route path='' element={<Navigate to={'locks'} />} />
                <Route path='locks' element={<Locks />} />
                <Route path='thermostats' element={<Thermostats />} />
              </Route>
            </Route>

            <Route path='properties'>
              <Route path='' element={<Properties />} />
            </Route>

            <Route path='properties/:propertyId'>
              <Route path='' element={<PropertyDetails />}>
                <Route path='' element={<Navigate to={'units'} />} />
                <Route path='overview' element={<PropertyOverview />} />
                <Route path='units' element={<Units />} />
                <Route path='staff' element={<PropertyDetailsStaff />} />
                <Route path='other_access' element={<OtherAccess />} />
                <Route path='message_setup' element={<MessageSetup />} />
              </Route>
            </Route>
            <Route
              path='properties/staff/:propertyId/:staffId'
              element={<StaffOverview />}
            />
            <Route
              path='properties/:propertyId/other_access/:unitId'
              element={<CommonAreaDetails />}
            />
            <Route
              path='properties/:propertyId/units/:unitId'
              element={<UnitDetails />}
            />

            <Route path='activity-logs'>
              <Route path='' element={<ActivityLog />}>
                <Route path='' element={<Navigate to={'locks'} />} />
                <Route path='locks' element={<LockActivityLogs />} />
              </Route>
            </Route>

            <Route path='activity-logs/:logId'>
              <Route path='' element={<ActivityLogOverview />} />
            </Route>

            <Route path='reports'>
              <Route path='' element={<Reports />}>
                <Route path='' element={<Navigate replace to={'people'} />} />
                <Route path='people' element={<ReportsPeople />}>
                  <Route path='' element={<Navigate replace to={'residents'} />} />
                  <Route path='residents' element={<ResidentsReports />} />
                  <Route path='staff' element={<StaffReports />} />
                  <Route path='guests' element={<GuestsReports />} />
                  <Route path='vendors' element={<VendorsReports />} />
                </Route>
              </Route>
            </Route>
            <Route
              path='reports/people/residents/:residentId'
              element={<ResidentsOverview />}
            >
              <Route
                path=''
                element={<NavigateWithParams replace={true} to={'overview'} />}
              />
              <Route path='overview' element={<ResidentOverviewPanel />} />
              <Route path='payment' element={<PaymentHistory />} />
              <Route path='metrics' element={<AppMetrics />} />
              <Route path='guests' element={<GuestsInformation />} />
            </Route>
            <Route path='reports/people/staff/:staffId' element={<StaffOverview />} />
            <Route
              path='reports/people/guests/:personAccessId'
              element={<GuestsOverview />}
            />

            <Route path='reports/people/vendors/:vendorId' element={<VendorOverview />} />
            <Route
              path='reports/people/vendors/:vendorId/user/:userId'
              element={<VendorUserOverview />}
            />

            <Route path='building-accounts' element={<BuildingAccounts />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ExportTableContextProvider>
  )
}

export default Router
