import {User} from 'firebase/auth'
import jwtDecode from 'jwt-decode'
import {useEffect, useState} from 'react'
import {useAuth} from 'reactfire'

type TToken = {
  apthub: {
    access: [
      {
        property_id: number
        building_id: number
        unit_id: number
        app_access: string
      },
    ]
    person_id: number
    person_type_code: string
    tenant_uuid: string
  }
}

const useUserPersonType = () => {
  const auth = useAuth()

  const [personCode, setPersonType] = useState('')

  useEffect(() => {
    updatePersonCode(auth.currentUser)
  }, [auth.currentUser])

  const updatePersonCode = async (user: User | null) => {
    if (!user) {
      setPersonType('')
      return
    }

    try {
      const token = await user.getIdToken()
      const decodedToken = jwtDecode<TToken>(token)

      if (decodedToken.apthub.person_id) {
        setPersonType(decodedToken.apthub.person_type_code)
      } else {
        setPersonType('')
      }
    } catch (e) {
      setPersonType('')
    }
  }

  return personCode
}

export default useUserPersonType
