// views/Login/Login.tsx

import './Login.style.scss'
import LoginForm from '../../forms/Login'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'

const Login = () => {
  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'login'} data-testid={'Login'}>
        <img alt='logo' className={'brand-mark'} src={'/images/apthub-mark.svg'} />
        <h2 className={'color-black'}>Welcome Back</h2>

        {process.env.NODE_ENV !== 'production' && <p>ENV: {process.env.NODE_ENV}</p>}
        <LoginForm />
      </div>
    </ErrorBoundary>
  )
}

export default Login
