import './Guests.style.scss'

import {useCallback, useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Badge from '../../../components/Badge'
import DataGrid from '../../../components/DataGrid'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import Paginator from '../../../components/Paginator'
import Search from '../../../layouts/People/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import Panel from '../../../components/Panel/Panel'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import {capitalize, toCommonDateFormat} from '../../../functions'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import useGuestsData from '../../../hooks/data/useGuestsData'
import GuestsFilters from './GuestsFilter'
import {BadgeProps} from '../../../components/Badge/Badge'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import {useQuery} from '@apollo/client'
import {TAllTypesResponse} from '../../../data/graphql/queries/common/types'
import {GET_ALL_TYPES} from '../../../data/graphql/queries/common'
import {handlePersonAccessCodeGuests} from '../../../functions/guests.functions'
import {AccessScheduleTypes} from '../../../data/graphql/mutations/lock/types'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'
import DeactivatePersonButton from '../../../components/DeactivatePersonButton'
import DeactivatePersonCell from '../../../components/DeactivatePersonCell'

type TGuestRow = {
  id: number
  personAccessId: number
  name: JSX.Element | string
  access: React.ReactNode
  assignedResident: string
  accessType: string
  property: string
  unit: string
  building: string
  unitId: number
  activeDate: string
  deactiveDate: React.ReactNode
}

const PAGE_SIZE = 10

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'name:asc', value: 'PERSON_NAME_ASC', label: 'Name Asc'},
  {sortKey: 'name:desc', value: 'PERSON_NAME_DESC', label: 'Name Desc'},
  {sortKey: 'status:asc', value: 'IS_ACTIVE_ASC', label: 'Active Asc'},
  {sortKey: 'status:desc', value: 'IS_ACTIVE_DESC', label: 'Active Desc'},
  {sortKey: 'access:asc', value: 'LOCK_SCHEDULE_TYPE_ASC', label: 'Schedule Type Asc'},
  {sortKey: 'access:desc', value: 'LOCK_SCHEDULE_TYPE_DESC', label: 'Schedule Type Desc'},
  {
    sortKey: 'assignedResident:asc',
    value: 'INVITED_BY_PERSON_NAME_ASC',
    label: 'Invited By Asc',
  },
  {
    sortKey: 'assignedResident:desc',
    value: 'INVITED_BY_PERSON_NAME_DESC',
    label: 'Invited By Desc',
  },
  {sortKey: 'property:asc', value: 'PROPERTY_NAME_ASC', label: 'Property Asc'},
  {sortKey: 'property:desc', value: 'PROPERTY_NAME_DESC', label: 'Property Desc'},
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Desc'},
  {sortKey: 'building:desc', value: 'BUILDING_NAME_ASC', label: 'Building Name Desc'},
  {sortKey: 'building:desc', value: 'BUILDING_NAME_DESC', label: 'Building Name Desc'},
  {
    sortKey: 'activeDate:asc',
    value: 'LOCK_SCHEDULE_START_DT_ASC',
    label: 'Active Date Asc',
  },
  {
    sortKey: 'activeDate:desc',
    value: 'LOCK_SCHEDULE_START_DT_DESC',
    label: 'Active Date Desc',
  },
  {
    sortKey: 'deactiveDate:asc',
    value: 'LOCK_SCHEDULE_END_DT_ASC',
    label: 'Deactive Date Asc',
  },
  {
    sortKey: 'deactiveDate:desc',
    value: 'LOCK_SCHEDULE_END_DT_DESC',
    label: 'Deactive Date Desc',
  },
]

const Guests = () => {
  const {setQuery} = useContext(ExportTableContext)
  const navigate = useNavigate()

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    setQueryOptions,
    onChangeNumberOfItems,
  } = useQueryOptions({
    limit: PAGE_SIZE,
    page: 1,
    orderBy: ['PERSON_NAME_ASC'],
    searchTerm: '',
    filters: {},
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    guests: guestsList,
    response: guestsResponse,
    variables: queryVariables,
    queryForDownloadTable,
  } = useGuestsData(debouncedSearchTerm, queryOptions)
  const allTypes = useQuery<TAllTypesResponse>(GET_ALL_TYPES)
  const allResidentTypes = allTypes.data?.transactionalDb?.allResidentTypes?.nodes
  const [guestsRows, setGuests] = useState<TGuestRow[]>([])

  const onChangeSortOrder = (value: string) => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  useEffect(() => {
    if (guestsList) {
      const newGuestsRows: TGuestRow[] = []

      guestsList.forEach(personAccess => {
        newGuestsRows.push({
          id: personAccess.personAccessId,
          personAccessId: personAccess.personAccessId,
          name: <CellWithAvatar name={personAccess.personName} />,
          access:
            renderAccessBadge(
              personAccess.lockScheduleType,
              personAccess.lockScheduleStartDt,
              personAccess.lockScheduleEndDt,
            ) || '—',
          assignedResident: personAccess.invitedByPersonName || '—',
          accessType: handlePersonAccessCodeGuests(personAccess.personAccessCode) || '-',
          property: personAccess.propertyName,
          unit: personAccess.unitNumber,
          building: personAccess.buildingName,
          unitId: personAccess.unitId,
          activeDate: toCommonDateFormat(personAccess.lockScheduleStartDt) || '—',
          deactiveDate: (
            <DeactivatePersonCell
              personId={personAccess.personId}
              onRevoke={guestsResponse.refetch}
            >
              {toCommonDateFormat(personAccess.lockScheduleEndDt) || '—'}
            </DeactivatePersonCell>
          ),
        })
      })

      setGuests(newGuestsRows)
    } else {
      setGuests([])
    }
  }, [guestsList, allResidentTypes])

  const renderAccessBadge = (
    accessType?: AccessScheduleTypes,
    startDate = '',
    endDate = '',
    forTable?,
  ) => {
    const now = Date.now()
    let theme: BadgeProps['theme'] = 'info'

    if (Date.parse(startDate) > now || Date.parse(endDate) < now) {
      theme = 'default'
    }

    return accessType ? (
      forTable ? (
        capitalize(accessType)
      ) : (
        <Badge theme={theme} size={'sm'}>
          {capitalize(accessType)}
        </Badge>
      )
    ) : null
  }

  const onChangeSearchQuery = useCallback(
    (query: string) => {
      upsertQueryOptions(prev => ({
        ...prev,
        searchTerm: query,
        page: 1,
      }))
    },
    [upsertQueryOptions],
  )

  const onPressRow = useCallback(
    (index: number) => {
      navigate(`/people/guests/${guestsRows[index].personAccessId}`)
    },
    [navigate, guestsRows],
  )

  const onSubmitFilter = useCallback(
    filters => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [setQueryOptions],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataForTableQuery = useCallback(async () => {
    try {
      const {data} = await queryForDownloadTable()

      const logs = data?.transactionalDb.allPortalPersonViews.nodes

      const tableData = logs.map(resident => {
        return Object.values({
          name: resident.personName || '—',
          access:
            renderAccessBadge(
              resident.lockScheduleType,
              resident.lockScheduleStartDt,
              resident.lockScheduleEndDt,
              true,
            ) || '—',
          assignedResident: resident.invitedByPersonName || '—',
          accessType: handlePersonAccessCodeGuests(resident.personAccessCode) || '—',
          property: resident.propertyName || '—',

          building: resident.buildingName || '—',
          unit: resident.unitNumber || '—',
          activeDate: toCommonDateFormat(resident.lockScheduleStartDt) || '—',
          deactiveDate: toCommonDateFormat(resident.lockScheduleEndDt) || '—',
        })
      })

      tableData.unshift([
        'Name',
        'Access',
        'Assigned Resident',
        'Access Type',
        'Property',
        'Building',
        'Unit',
        'Active Date',
        'Deactive Date',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const emptyGuestsTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Guests table is empty...',
  })

  const itemsCount =
    guestsResponse.data?.transactionalDb.allPortalPersonViews.totalCount || 0

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Guests'} data-testid={'GuestsView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search guests'
                    value={queryOptions.searchTerm}
                    onValueChange={onChangeSearchQuery}
                  />
                  <SearchFilters
                    filter={GuestsFilters}
                    initialValue={queryOptions.filters}
                    onSubmit={onSubmitFilter}
                  />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>

                <Panel theme={'white'}>
                  <DataGrid
                    loading={guestsResponse.loading}
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    order={tableSort.order}
                    columns={[
                      {name: 'Name', key: 'name', sortable: true},
                      {name: 'Access', key: 'access', sortable: true},
                      {
                        name: 'Assigned Resident',
                        key: 'assignedResident',
                        sortable: true,
                      },
                      {name: 'Access Type', key: 'accessType'},
                      {name: 'Property', key: 'property', sortable: true},
                      {name: 'Building', key: 'building', sortable: true},
                      {name: 'Unit', key: 'unit', sortable: true},
                      {name: 'Active Date', key: 'activeDate', sortable: true},
                      {name: 'Deactive Date', key: 'deactiveDate', sortable: true},
                    ]}
                    selectableRows
                    emptyTableComponent={emptyGuestsTable}
                    onRowSelect={onPressRow}
                    rows={guestsRows}
                  />
                </Panel>

                <TableFooter itemCount={itemsCount} loading={guestsResponse.loading}>
                  <Paginator
                    itemCount={itemsCount}
                    perPage={queryOptions.limit}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default Guests
