import {QueryHookOptions, useQuery} from '@apollo/client'
import {
  TPersonAccessesByIdResponse,
  TPersonAccessesByIdVariables,
} from '../../data/graphql/queries/people/types'
import {GET_PERSON_ACCESSES_BY_ID} from '../../data/graphql/queries/people'

const usePersonAccesses = (
  personId?: number,
  options?: QueryHookOptions<TPersonAccessesByIdResponse, TPersonAccessesByIdVariables>,
) => {
  const response = useQuery<TPersonAccessesByIdResponse, TPersonAccessesByIdVariables>(
    GET_PERSON_ACCESSES_BY_ID,
    {
      ...options,
      skip: !personId,
      variables: {
        personId: Number(personId),
        condition: {
          isActive: true,
          isDeleted: false,
        },
      },
    },
  )

  const data = response?.data?.transactionalDb?.personByPersonId

  return {
    data,
    response,
  }
}

export default usePersonAccesses
