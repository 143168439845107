import './DeactivatePersonCell.style.scss'

import DeactivatePersonButton from '../DeactivatePersonButton'

interface DeactivatePersonCellProps {
  personId: number
  className?: string
  children?: React.ReactNode
  onRevoke?: () => void
}

const DeactivatePersonCell = ({
  className = '',
  personId,
  children,
  onRevoke,
}: DeactivatePersonCellProps) => {
  return (
    <div className={`deactivatePersonCell ${className}`}>
      {children}

      <DeactivatePersonButton
        personId={personId}
        className='deactivatePersonButton'
        onRevoke={onRevoke}
      />
    </div>
  )
}

export default DeactivatePersonCell
