// components/Sidebar/SidebarMenuDashboard.tsx

import React, {FC} from 'react'
import './SidebarMenu.style.scss'
import SidebarMenuItem from './SidebarMenuItem'
import {SidebarMenuProps} from './SidebarMenu'
import useToast from '../../hooks/useToast'
import useUserAccess from '../../hooks/useUserAccess'
import {useQuery} from '@apollo/client'
import {GET_AVAILABLE_PROPERTIES} from '../../data/graphql/queries/properties'
import {
  TAvailablePropertiesCountResponse,
  TAvailablePropertiesCountVariables,
} from '../../data/graphql/queries/properties/types'

const defaultProps: SidebarMenuProps = {}

const DashboardMenu: FC<SidebarMenuProps> = (props: SidebarMenuProps) => {
  props = {...defaultProps, ...props}
  const {showToast} = useToast()
  const userAccess = useUserAccess()

  const {data} = useQuery<
    TAvailablePropertiesCountResponse,
    TAvailablePropertiesCountVariables
  >(GET_AVAILABLE_PROPERTIES, {
    variables: {
      filter: {
        propertyId: {
          in: userAccess.properties,
        },
      },
    },
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to receive properties count',
        type: 'error',
      })
    },
  })
  return (
    <nav data-testid={'SidebarMenu'}>
      <ul className={'sidebar-menu'}>
        <li>
          <SidebarMenuItem link={'/overview'} label={'Overview'} icon={'layout'} />
        </li>
        <li>
          <SidebarMenuItem link={'/people'} label={'People'} icon={'users'} />
        </li>
        {/* <li>
          <SidebarMenuItem
            link={'/roles-and-permissions'}
            label={'Roles & Permissions'}
            icon={'sliders'}
          />
        </li> */}
        <li>
          <SidebarMenuItem
            link={'/properties'}
            label={'Properties'}
            icon={'map-pin'}
            badge={data?.transactionalDb?.allProperties?.totalCount || '-'}
          />
        </li>
        <li>
          <SidebarMenuItem
            link={'/activity-logs'}
            label={'Activity Logs'}
            icon={'activity'}
          />
        </li>
        <li>
          <SidebarMenuItem link={'/reports'} label={'Reports'} icon={'file-text'} />
        </li>

        <li>
          <SidebarMenuItem link={'/devices'} label={'Devices'} icon={'lock'} />
        </li>
      </ul>
    </nav>
  )
}

export default DashboardMenu
