import {useLazyQuery, useMutation, useQuery} from '@apollo/client'
import {
  RetryInfo,
  TGetAsyncTransactionsResponse,
  TGetAsyncTransactionsVariables,
  TGetTransactionRetryInfoResponse,
  TGetTransactionRetryInfoVariables,
} from '../../data/graphql/queries/people/types'
import {useCallback, useMemo} from 'react'
import {
  TCancelAsyncTransactionResponse,
  TCancelAsyncTransactionVariables,
  TRevokeAccessToLocksResponse,
  TRevokeAccessToLocksVariables,
  TSetLockPinsResponse,
  TSetLockPinsVariables,
} from '../../data/graphql/mutations/lock/types'
import {
  CANCEL_ASYNC_TRANSACTION,
  REVOKE_ACCESSES_TO_LOCKS,
  SET_LOCK_PINS,
} from '../../data/graphql/mutations/lock'
import {DeviceMaker, isCommonError} from '../../data/graphql/types'
import useToast from '../useToast'
import {
  GET_ASYNC_TRANSACTIONS,
  GET_TRANSACTION_RETRY_INFO,
} from '../../data/graphql/queries/people'
import {client} from '../../data/graphql'
import {isUnitNumber, prepareOrder} from '../../functions/filters'
import {QueryOptions} from '../../models'

export type TTransaction =
  TGetAsyncTransactionsResponse['transactionalDb']['allAsyncTransactionViews']['nodes'][number]

const usePinTransactions = (
  searchTerm: string,
  options: QueryOptions<any> & {
    targetPersonId?: number
    initiatorPersonId?: number
    status?: 'Failed' | 'Pending'
  },
) => {
  const {showToast} = useToast()
  const limit = options.limit
  const offset = options.page ? options.page - 1 : 0

  const variables: TGetAsyncTransactionsVariables = {
    first: limit,
    offset: offset,
    orderBy: prepareOrder(options?.orderBy),
    condition: {
      status: options.status,
      targetPersonId: options.targetPersonId,
      initiatorPersonId: options.initiatorPersonId,
    },
    filter: {
      buildingId: {
        notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
      },
      vendorId: {
        isNull: false,
      },
    },
  }

  if (searchTerm && variables.filter) {
    variables.filter[isUnitNumber(searchTerm) ? 'unitNumber' : 'personName'] = {
      includesInsensitive: searchTerm,
    }
  }

  const transactionsResponse = useQuery<
    TGetAsyncTransactionsResponse,
    TGetAsyncTransactionsVariables
  >(GET_ASYNC_TRANSACTIONS, {
    variables,
  })

  const transactions =
    transactionsResponse?.data?.transactionalDb?.allAsyncTransactionViews?.nodes
  const totalCount =
    transactionsResponse.data?.transactionalDb?.allAsyncTransactionViews.totalCount || 0

  const [revokeLockAccesses] = useMutation<
    TRevokeAccessToLocksResponse,
    TRevokeAccessToLocksVariables
  >(REVOKE_ACCESSES_TO_LOCKS)

  const [getRetryInfoQuery, retryInfoResponse] = useLazyQuery<
    TGetTransactionRetryInfoResponse,
    TGetTransactionRetryInfoVariables
  >(GET_TRANSACTION_RETRY_INFO)

  const {deviceTransactionsMap, unitTransactionsMap} = useMemo(() => {
    const result: {
      deviceTransactionsMap: Record<string, TTransaction>
      unitTransactionsMap: Record<string, TTransaction>
    } = {
      deviceTransactionsMap: {},
      unitTransactionsMap: {},
    }

    transactions?.forEach(transaction => {
      result.deviceTransactionsMap[transaction.installedDeviceId] = transaction
      result.unitTransactionsMap[transaction.unitId] = transaction
    }, result)

    return result
  }, [transactions])

  const [cancelTransactionMutation, cancelTransactionResponse] = useMutation<
    TCancelAsyncTransactionResponse,
    TCancelAsyncTransactionVariables
  >(CANCEL_ASYNC_TRANSACTION)

  const [setLockPins, setLockPinsResponse] = useMutation<
    TSetLockPinsResponse,
    TSetLockPinsVariables
  >(SET_LOCK_PINS)

  const retryLoadOperation = useCallback(
    async (retryInfo: RetryInfo) => {
      try {
        const response = await setLockPins({
          variables: {
            input: {
              deviceMaker: DeviceMaker.YALE,
              userId: retryInfo.mutationInput.userId,
              locksToSet: retryInfo.mutationInput.locksToSet,
            },
          },
        })

        const data = response.data?.lock.setLockPins

        if (isCommonError(data) || data?.failed.length) {
          throw new Error('Failed to set lock pin')
        }
      } catch (e) {
        showToast({
          title: 'Error',
          message: `Failed to set lock pin`,
          type: 'error',
        })
      }
    },
    [setLockPins, showToast],
  )

  const retryDeleteOperation = useCallback(
    async (yaleUserId: string, yaleLockId: string) => {
      try {
        const response = await revokeLockAccesses({
          variables: {
            input: {
              yaleUserId,
              yaleLockIds: [yaleLockId],
              deviceMaker: DeviceMaker.YALE,
            },
          },
        })

        const data = response.data?.lock?.revokeAccessToLocks

        if (isCommonError(data) || data?.failed.length) {
          throw new Error('Failed to revoke user access')
        }

        showToast({
          title: 'Accesses Updated',
          message: 'Lock access has been revoked',
          type: 'info',
        })
      } catch (e) {
        showToast({
          title: 'Request Error',
          message: 'Failed to revoke user access',
          type: 'error',
        })
      }
    },
    [revokeLockAccesses, showToast],
  )
  const requestCancelTransaction = useCallback(
    async (asyncTransactionId: number) => {
      const response = await cancelTransactionMutation({
        variables: {
          input: {
            asyncTransactionId,
          },
        },
        onCompleted() {
          client.cache.evict({id: 'AsyncTransactionView:' + asyncTransactionId})
        },
      })

      const data = response.data?.utility?.cancelUnsuccessfulAsyncTransaction

      if (isCommonError(data)) {
        throw new Error('Failed to cancel transaction')
      }

      return data
    },
    [cancelTransactionMutation],
  )

  const getRetryInfo = useCallback(
    async (transactionId: number) => {
      const retryInfoResponse = await getRetryInfoQuery({
        variables: {
          asyncTransactionId: transactionId,
        },
      })

      return retryInfoResponse.data?.transactionalDb.transaction.retryInfo
    },
    [getRetryInfoQuery],
  )

  const retryTransaction = useCallback(
    async ({asyncTransactionId, ...transaction}: TTransaction) => {
      try {
        const [retryInfo] = await Promise.all([
          getRetryInfo(+asyncTransactionId),
          requestCancelTransaction(+asyncTransactionId),
        ])

        if (!retryInfo) {
          throw new Error('Failed to get transaction retry info')
        }

        if (
          transaction.pinOperationType === 'load' &&
          retryInfo?.mutationName === 'setLockPins'
        ) {
          await retryLoadOperation(retryInfo)
        } else if (transaction.pinOperationType === 'delete' && retryInfo) {
          await retryDeleteOperation(
            retryInfo.mutationInput.userId,
            retryInfo.mutationInput.locksToSet[0].deviceId,
          )
        } else {
          throw new Error('No operation type')
        }

        transactionsResponse.refetch()
      } catch (e) {
        showToast({
          title: 'Error',
          message: 'Failed to retry transaction',
          type: 'error',
        })
      }
    },
    [
      transactionsResponse.refetch,
      getRetryInfo,
      requestCancelTransaction,
      retryLoadOperation,
      retryDeleteOperation,
      showToast,
    ],
  )

  const cancelTransaction = useCallback(
    async (asyncTransactionId: number) => {
      try {
        await requestCancelTransaction(asyncTransactionId)

        showToast({
          title: 'Success',
          message: 'Transaction has been cancelled',
          type: 'info',
        })
      } catch (e) {
        showToast({
          title: 'Error',
          message: 'Failed to cancel transaction',
          type: 'error',
        })
      }
    },
    [requestCancelTransaction, showToast],
  )

  return {
    loading: transactionsResponse.loading,
    data: deviceTransactionsMap,
    deviceTransactionsMap,
    unitTransactionsMap,
    cancelTransaction,
    retryTransaction,
    retryLoadOperation,
    actionLoading:
      retryInfoResponse.loading ||
      cancelTransactionResponse.loading ||
      setLockPinsResponse.loading,
    totalCount,
  }
}

export default usePinTransactions
