import '../ResidentsOverview.style.scss'

import React, {useEffect, useState} from 'react'
import Badge from '../../../components/Badge'
import Card from '../../../components/Card/Card'
import DataGrid from '../../../components/DataGrid'
import Column from '../../../components/Grid/Column'
import {capitalize, convertToNiceDate} from '../../../functions'
import {getLogEventInfo} from '../../../functions/logs'
import useLockActivityLogs from '../../../hooks/useLockActivityLogs'
import CrashScreen from '../../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import {useQueryOptions} from '../../../hooks/useQueryOptions'
import {useParams, useSearchParams} from 'react-router-dom'

const RecentActivity = () => {
  const params = useParams<{residentId: string}>()
  const [searchParams] = useSearchParams()
  const [activityData, setActivityData] = useState<any[]>([])

  const {queryOptions} = useQueryOptions({limit: 5})
  const activityLogResponse = useLockActivityLogs('', queryOptions, {
    condition: {
      personId: Number(params.residentId),
      unitId: Number(searchParams.get('unitId')),
    },
  })
  const allLogs = activityLogResponse.data?.transactionalDb?.allLockActivityViews.nodes

  useEffect(() => {
    if (allLogs) {
      setActivityData(
        allLogs.map(item => {
          const eventInfo = getLogEventInfo(item)

          return {
            state: (
              <Badge size={'sm'} theme={eventInfo.type}>
                {eventInfo.label}
              </Badge>
            ),
            type: capitalize(item.device),
            date: convertToNiceDate(+item.timeStamp) || '-',
          }
        }),
      )
    } else {
      setActivityData([])
    }
  }, [allLogs])

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <Card padding='sm'>
        <div className='recent-activity'>
          <Column>
            <h6>Recent Activity</h6>
            <DataGrid
              loading={activityLogResponse.loading}
              columns={[
                {key: 'state', name: 'State'},
                {key: 'type', name: 'Type'},
                {key: 'date', name: 'Date'},
              ]}
              rows={activityData}
              withHeader={false}
              minHeight
            />
          </Column>
        </div>
      </Card>
    </ErrorBoundary>
  )
}

export default RecentActivity
