import {User} from 'firebase/auth'
import jwtDecode from 'jwt-decode'
import {useEffect, useState} from 'react'
import {useAuth} from 'reactfire'
import {client} from '../data/graphql'
import {GET_PROPERTY_ID_BY_PERSON_ID} from '../data/graphql/queries/people'
import {
  TGetProperyIdByPersonID,
  TGetProperyIdByPersonIDVariables,
} from '../data/graphql/queries/people/types'

type TToken = {
  apthub: {
    access: [
      {
        property_id: number
        building_id: number
        unit_id: number
        app_access: string
      },
    ]
    person_id: number
    person_type_code: string
    tenant_uuid: string
  }
}

const useUserAccess = () => {
  const auth = useAuth()

  const [access, setAccess] = useState<{
    properties: number[]
    loading: boolean
  }>({
    properties: [],
    loading: true,
  })

  useEffect(() => {
    getAccessProperties(auth.currentUser)
      .then(properties => {
        setAccess({properties, loading: false})
      })
      .catch(() => {
        setAccess({properties: [], loading: false})
      })
  }, [auth.currentUser])

  const getAccessProperties = async (user: User | null) => {
    if (user) {
      const token = await auth.currentUser?.getIdToken()

      if (token) {
        try {
          const decodedToken = jwtDecode<TToken>(token)

          if (decodedToken.apthub.person_id) {
            const response = await client.query<
              TGetProperyIdByPersonID,
              TGetProperyIdByPersonIDVariables
            >({
              query: GET_PROPERTY_ID_BY_PERSON_ID,
              variables: {
                filter: {
                  personId: {
                    equalTo: decodedToken.apthub.person_id,
                  },
                },
              },
            })

            const employeeProperties =
              response?.data?.transactionalDb?.allPersonEmployees?.nodes || []
            const allProperties = response?.data?.transactionalDb?.allProperties?.nodes

            if (employeeProperties.length > 0) {
              const employeePropertyIds = employeeProperties.map(
                ({propertyId}) => propertyId,
              )
              const uniqueEmployeeProperties = Array.from(new Set(employeePropertyIds))
              const isCorporateEmployee = employeePropertyIds.includes(null)

              if (isCorporateEmployee) {
                return allProperties.map(({propertyId}) => Number(propertyId))
              }

              return uniqueEmployeeProperties.map(id => Number(id))
            }

            return []
          }

          return []
        } catch (e) {
          return []
        }
      }
    }

    return []
  }

  return access
}

export default useUserAccess
