import {useCallback, useState} from 'react'

import {client} from '../../../data/graphql'
import {TResidentTypeCode} from '../../../data/graphql/queries/common/types'
import {SEND_APP_INVITE} from '../../../data/graphql/queries/utility'
import {
  TSendAppInviteResponse,
  TSendAppInviteVariables,
} from '../../../data/graphql/queries/utility/types'
export interface TPendingUser {
  personName: string
  unitId: number
  propertyId: string
  residentType: string
  personType: string
  email: string
  mobilePhone: string
}

const residentType = {
  [TResidentTypeCode.HOH]: 'HEAD_OF_HOUSEHOLD',
  [TResidentTypeCode.CHOH]: 'CO_HEAD_OF_HOUSEHOLD',
  [TResidentTypeCode.AR]: 'ADULT_RESIDENT',
  [TResidentTypeCode.CR]: 'CHILD_RESIDENT',
}

const usePendingUserInvite = () => {
  const [loading, setLoading] = useState(false)

  const invite = useCallback(
    async (
      user: TPendingUser,
      devices: {
        installedLockIds: string[]
        installedThermostatIds: string[]
      },
    ) => {
      setLoading(true)

      try {
        const [firstName, ...lastName] = user.personName.split(/\s/)

        if (!devices.installedLockIds?.length) {
          throw new Error('No installed devices')
        }

        const response = await client.mutate<
          TSendAppInviteResponse,
          TSendAppInviteVariables
        >({
          mutation: SEND_APP_INVITE,
          variables: {
            input: {
              sender: {
                lastName: 'Platform',
                firstName: 'Apthub',
              },
              invitee: {
                email: user.email,
                firstName: firstName,
                lastName: lastName.join(' '),
                mobilePhone: user.mobilePhone,
                personType: 'RESIDENT',
                residentType: residentType[user.residentType],
              },
              requestedThermostatAccesses: devices.installedThermostatIds.map(
                installedDeviceId => ({
                  installedDeviceId: +installedDeviceId,
                }),
              ),
              requestedCommonAreaLockAccesses: [{propertyId: Number(user.propertyId)}],
              requestedLockAccesses: devices.installedLockIds.map(installedDeviceId => ({
                installedDeviceId: +installedDeviceId,
              })),
            },
          },
        })

        setLoading(false)
        return response
      } catch (e) {
        setLoading(false)
        throw e
      }
    },
    [],
  )

  return {invite, loading}
}

export default usePendingUserInvite
