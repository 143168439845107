import {useQuery} from '@apollo/client'
import {GET_DEVICES_STATUS} from '../../data/graphql/queries/devices'
import {
  TGetDevicesStatusResponse,
  TGetDevicesStatusVariables,
} from '../../data/graphql/queries/devices/types'
import useToast from '../useToast'
import {useMemo} from 'react'

export const useDevicesStatus = () => {
  const {showToast} = useToast()
  const statusQuery = useQuery<TGetDevicesStatusResponse, TGetDevicesStatusVariables>(
    GET_DEVICES_STATUS,
    {
      variables: {
        lockLowBatteryCondition: {
          deviceClassType: 'L',
        },
        lockLowBatteryFilter: {
          buildingId: {
            notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
          },
          deviceBatteryLevel: {
            lessThan: '0.25',
          },
        },
        lockOfflineCondition: {
          deviceClassType: 'L',
          deviceStatusCurrent: 'offline',
        },
        lockOfflineFilter: {
          buildingId: {
            notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
          },
        },
        thermostatOfflineCondition: {
          deviceClassType: 'T',
          deviceStatusCurrent: 'offline',
        },
        thermostatOfflineFilter: {
          buildingId: {
            notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
          },
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Devices Status Data',
          type: 'error',
        })
      },
    },
  )

  const counters = useMemo(() => {
    const data = statusQuery.data?.transactionalDb

    return {
      locksOffline: data?.locksOffline?.totalCount || 0,
      locksLowBattery: data?.locksLowBattery?.totalCount || 0,
      theromstatsOffline: data?.theromstatsOffline?.totalCount || 0,
    }
  }, [statusQuery.data?.transactionalDb])

  return {statusQuery, counters}
}
