import {useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {useParams} from 'react-router-dom'
import {
  ASSIGN_INSTALLATION_TASKS,
  CANCEL_INSTALLATION_TASKS,
} from '../../data/graphql/mutations/people'
import {
  TAssignInstallationTasksResponse,
  TAssignInstallationTasksVariables,
  TCancelInstallationTasksResponse,
  TCancelInstallationTasksVariables,
} from '../../data/graphql/mutations/people/types'
import useLockAccesses from '../../hooks/useLockAccesses'
import useToast from '../../hooks/useToast'
import {TVendorUserLayoutSubmitData} from '../../layouts/VendorUserLayout/VendorUserLayout'
import {DeviceTypes} from '../../data/graphql/queries/common/types'

const useInstallerVendorUserOverview = () => {
  const {showErrorToast, showInfoToast} = useToast()

  const {userId} = useParams()
  const personId = Number(userId)
  const {grantCommonAreaAccesses} = useLockAccesses(personId)

  const [assignInstallationTasksRequest] = useMutation<
    TAssignInstallationTasksResponse,
    TAssignInstallationTasksVariables
  >(ASSIGN_INSTALLATION_TASKS)

  const [cancelInstallationTasksRequest] = useMutation<
    TCancelInstallationTasksResponse,
    TCancelInstallationTasksVariables
  >(CANCEL_INSTALLATION_TASKS)

  const assignInstallationTasks = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      let deviceInventoryViewIds: string[] = []
      const brivoDeviceInventoryIds: string[] = []

      input.tasks.installationTasks.devicesToInstall.forEach(({deviceInventoryId}) => {
        const [_, deviceTypeId] = deviceInventoryId.split(':')
        const isBrivo = +deviceTypeId === DeviceTypes.BRIVO

        if (isBrivo) {
          brivoDeviceInventoryIds.push(deviceInventoryId)
        }

        deviceInventoryViewIds.push(deviceInventoryId)
      })

      if (!deviceInventoryViewIds.length) {
        return Promise.resolve()
      }

      try {
        await grantCommonAreaAccesses(input.requestedPropertyCommonAreaAccess)
      } catch (e) {
        showErrorToast(
          'Device work assignments',
          'Failed to create common areas device work assignments',
        )

        deviceInventoryViewIds = deviceInventoryViewIds.filter(
          id => !brivoDeviceInventoryIds.includes(id),
        )
      }

      if (!deviceInventoryViewIds.length) {
        return
      }

      try {
        await assignInstallationTasksRequest({
          variables: {
            input: {
              personIdVendor: personId,
              deviceInventoryViewIds,
            },
          },
        })

        showInfoToast(
          'Device work assignments',
          'Device work assignments have been created',
        )
      } catch (e) {
        showErrorToast(
          'Device work assignments',
          'Failed to create device work assignments',
        )
      }
    },
    [
      assignInstallationTasksRequest,
      grantCommonAreaAccesses,
      personId,
      showErrorToast,
      showInfoToast,
    ],
  )

  const cancelInstallationTasks = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      const deviceWorkAssignmentIds = input.tasks.installationTasks.devicesToUnassign.map(
        el => +el.deviceWorkAssignmentId,
      )

      if (!deviceWorkAssignmentIds.length) {
        return Promise.resolve()
      }

      try {
        const response = await cancelInstallationTasksRequest({
          variables: {
            input: {
              deviceWorkAssignmentIds,
            },
          },
        })

        if (response.data?.utility.cancelTasks?.failed?.length) {
          throw Error('One more more tasks could not be cancelled')
        }

        showInfoToast(
          'Device work assignments',
          'Selected device work assignments has been cancelled',
        )
      } catch (e) {
        showErrorToast(
          'Device work assignments',
          'One more more device work assignments could not be cancelled',
        )
      }
    },
    [cancelInstallationTasksRequest, showErrorToast],
  )

  return {assignInstallationTasks, cancelInstallationTasks}
}

export default useInstallerVendorUserOverview
