import {useCallback, useMemo} from 'react'
import {useParams} from 'react-router-dom'

import {DeviceMaker} from '../../data/graphql/types'

import useLockAccesses from '../../hooks/useLockAccesses'
import {TServiceTaskAccess} from '../../layouts/VendorUserLayout/useScheduleAccessPoints'

import {TVendorUserLayoutSubmitData} from '../../layouts/VendorUserLayout/VendorUserLayout'
import {TAccessType} from '../../components/AccessSchedule/AccessSchedule'
import {useMutation} from '@apollo/client'
import {
  TCreatePinOnlyUserResponse,
  TCreatePinOnlyUserVariables,
} from '../../data/graphql/mutations/people/types'
import {CREATE_PIN_ONLY_USER} from '../../data/graphql/mutations/people'

type TCommonAreaAccess = {
  accessType: TAccessType
  propertyId: string
  startDate?: string
  endDate?: string
}

const useServiceVendorUserOverview = () => {
  const params = useParams<{userId?: string; vendorId: string}>()
  const personId = Number(params.userId)
  const vendorId = Number(params.vendorId)

  const {
    updateAppAccesses,
    updatePinAccesses,
    revokeLockAccesses,
    grantCommonAreaAccesses,
    revokeCommonAreaAccesses,
    refreshPersonAccesses,
  } = useLockAccesses(personId)

  const [createPinOnlyUserRequest] = useMutation<
    TCreatePinOnlyUserResponse,
    TCreatePinOnlyUserVariables
  >(CREATE_PIN_ONLY_USER)

  const grantPinAccesses = useCallback(
    async (
      input: TVendorUserLayoutSubmitData,
      person?: {
        personId: number
        yaleUserId?: string
      },
    ) => {
      const pinAccesses: TServiceTaskAccess[] = input.requestedLockAccesses
      const commonAreasPinAccesses: TCommonAreaAccess[] =
        input.requestedPropertyCommonAreaAccess.filter(
          ({accessType}) => accessType === 'pin',
        )

      return await Promise.allSettled([
        updatePinAccesses(pinAccesses, person?.yaleUserId),
        grantCommonAreaAccesses(commonAreasPinAccesses, person?.personId),
      ])
    },
    [grantCommonAreaAccesses, updatePinAccesses],
  )

  const grantAppAccesses = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      const appAccesses: TServiceTaskAccess[] = input.requestedLockAccesses.filter(
        ({accessLevel}) => accessLevel === 'app',
      )
      const commonAreasAppAccesses: TCommonAreaAccess[] =
        input.requestedPropertyCommonAreaAccess.filter(
          ({accessType}) => accessType === 'app',
        )

      return await Promise.allSettled([
        updateAppAccesses(appAccesses),
        grantCommonAreaAccesses(commonAreasAppAccesses),
      ])
    },
    [grantCommonAreaAccesses, updateAppAccesses],
  )

  const createPinOnlyUser = useCallback(
    async (
      invitee: TVendorUserLayoutSubmitData['invitee'],
      deviceMaker: DeviceMaker,
      yaleLockId?: string,
    ) => {
      const input: TCreatePinOnlyUserVariables['input'] = {
        deviceMaker,
        email: invitee.email,
        firstName: invitee.firstName,
        lastName: invitee.lastName,
        phone: invitee.mobilePhone,
        vendorId,
      }

      if (yaleLockId) {
        input.yale = {
          credentialType: 'PIN',
          deviceId: yaleLockId,
        }
      }

      const response = await createPinOnlyUserRequest({
        variables: {
          input,
        },
      })

      const person = response.data?.lock.createPinOnlyUser

      if (!person?.personId || (yaleLockId && !person?.yale?.userId)) {
        throw Error('Failed to create unverified yale user')
      }

      return {
        personId: person?.personId,
        yaleUserId: person?.yale?.userId,
      }
    },
    [createPinOnlyUserRequest],
  )

  const createBrivoPinUser = useCallback(
    (invitee: TVendorUserLayoutSubmitData['invitee']) => {
      return createPinOnlyUser(invitee, DeviceMaker.BRIVO)
    },
    [createPinOnlyUser],
  )

  const createYalePinUser = useCallback(
    (invitee: TVendorUserLayoutSubmitData['invitee'], yaleLockId?: string) => {
      return createPinOnlyUser(invitee, DeviceMaker.YALE, yaleLockId)
    },
    [createPinOnlyUser],
  )

  const revokeAccesses = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      return await Promise.allSettled([
        revokeCommonAreaAccesses(input.deletedPropertyCommonAreaAccess),
        revokeLockAccesses(input.deletedLockAccesses),
      ])
    },
    [revokeCommonAreaAccesses, revokeLockAccesses],
  )

  const result = useMemo(
    () => ({
      revokeAccesses,
      grantAppAccesses,
      grantPinAccesses,
      createBrivoPinUser,
      createYalePinUser,
      refreshPersonAccesses,
    }),
    [
      createBrivoPinUser,
      createYalePinUser,
      grantAppAccesses,
      grantPinAccesses,
      refreshPersonAccesses,
      revokeAccesses,
    ],
  )

  return result
}

export default useServiceVendorUserOverview
