import './Overview.style.scss'

import React from 'react'
import Section from '../../components/Grid/Section'
import Metrics from './Metrics'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'
import Transactions from './Transactions'

const Overview = () => {
  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Overview'} data-testid={'Overview'}>
        <Section spacing='md'>
          <h3 className={'color-black overview-title'}>Overview</h3>
        </Section>

        <Metrics />

        <Section>
          <h4 className='black'>Your failed pin transactions</h4>
        </Section>

        <Transactions />
      </div>
    </ErrorBoundary>
  )
}

export default React.memo(Overview)
