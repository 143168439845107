import {useQuery} from '@apollo/client'
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumbs'
import Column from '../../components/Grid/Column'
import Row from '../../components/Grid/Row'
import Section from '../../components/Grid/Section'
import Tabbar from '../../components/Tabbar'
import './ResidentsOverview.style.scss'
import {
  TPortalPersonListResponse,
  TPortalPersonListVariables,
} from '../../data/graphql/queries/people/types'
import {GET_PORTAL_PERSON_LIST} from '../../data/graphql/queries/people'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import useToast from '../../hooks/useToast'
import Spinner from '../../components/Spinner/Spinner'
import {formatPhoneNumber} from '../../functions'
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader'

const ResidentsOverview = () => {
  const {showToast} = useToast()
  const navigate = useNavigate()
  const location = useLocation()
  const pathNodes = location.pathname.split('/')
  const params = useParams<{residentId: string}>()
  const [searchParams] = useSearchParams()

  const id = Number(params.residentId)
  const panel = pathNodes[pathNodes.length - 1]
  const unitId = searchParams.get('unitId')

  const guest = useQuery<TPortalPersonListResponse, TPortalPersonListVariables>(
    GET_PORTAL_PERSON_LIST,
    {
      variables: {
        condition: {
          isPersonAccessActive: true,
          personId: +id,
          ...(unitId ? {unitId: +unitId} : {}),
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Person List Data',
          type: 'error',
        })
      },
    },
  )

  const guestAccess = guest.data?.transactionalDb.allPortalPersonViews.nodes?.[0]

  const breadcrumbs = [
    {name: 'People', url: '/people/'},
    {name: 'Residents', url: '/people/residents'},
    {
      name: guestAccess?.personName || '',
      url: `/people/residents/${guestAccess?.personId || ''}/overview?unitId=${
        guestAccess?.unitId || ''
      }`,
    },
    {name: 'Overview', url: ''},
  ]

  const onPressActivity = () => {
    navigate(
      encodeURI(
        `/activity-logs/locks?limit=10&page=1&orderBy=TIME_STAMP_ASC&searchTerm=${guestAccess?.personName}&units=[${unitId}]`,
      ),
    )
  }

  const unitLabel = guestAccess?.unitNumber ? 'Unit ' + guestAccess?.unitNumber : ''
  const mobilePhone = formatPhoneNumber(guestAccess?.mobilePhone)

  if (guest.loading) {
    return <Spinner />
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'ResidentsOverview'} data-testid={'ResidentsOverview'}>
        <Section>
          <Breadcrumbs showBack goBackUrl='/people/residents' crumbs={breadcrumbs} />
        </Section>

        <ProfileHeader id='resident-personal-info' spacing={'section-md'}>
          <ProfileHeader.PersonCard
            name={guestAccess?.personName}
            subtitle={guestAccess?.propertyName}
            badgeTheme={'danger'}
            badgeLabel={unitLabel}
          />

          <ProfileHeader.InfoCard
            title={'Call'}
            subtitle={mobilePhone}
            className='phone-number-section'
          />
          <ProfileHeader.InfoCard
            title={'Email'}
            subtitle={guestAccess?.email}
            className='email-section'
          />
          <ProfileHeader.ButtonCard
            label='Activity'
            onClick={onPressActivity}
            className='action-section'
          />
        </ProfileHeader>

        <Section id='resident-content' spacing='none'>
          <Row>
            <Column xs={'12'}>
              <Tabbar
                tabs={[
                  {label: 'Overview', key: 'overview'},
                  {label: 'App Metrics', key: 'metrics'},
                  {label: 'Payment History', key: 'payment'},
                  {label: 'Guests Information', key: 'guests'},
                ]}
                selected={panel}
                onTabChange={key => {
                  navigate(`${key}?${searchParams.toString()}`)
                }}
              />
            </Column>
          </Row>
        </Section>

        <Outlet />
      </div>
    </ErrorBoundary>
  )
}

export default ResidentsOverview
