import {useCallback, useContext, useEffect, useState} from 'react'
import './People.style.scss'

import {useNavigate} from 'react-router-dom'
import Badge from '../../../components/Badge'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import People from './People'
import ResidentsFilters, {
  TResidentFilterFields,
} from '../../People/Residents/ResidentsFilter'
import useResidentsData from '../../../hooks/data/useResidentsData'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import {toCommonDateFormat} from '../../../functions'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'

export interface ResidentPreview {
  name: string
  nameWithAvatar: React.ReactNode
  property: string
  building: string
  unit: string
  unitId: number
  moveIn: string
  moveOut: string
  renewal: string
  expiration: string
  id: number
  leaseId: number
}

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'nameWithAvatar:asc', value: 'PERSON_NAME_ASC', label: 'Name Asc'},
  {sortKey: 'nameWithAvatar:desc', value: 'PERSON_NAME_DESC', label: 'Name Desc'},
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Desc'},
  {sortKey: 'building:asc', value: 'BUILDING_NAME_ASC', label: 'Building Asc'},
  {sortKey: 'building:desc', value: 'BUILDING_NAME_DESC', label: 'Building Desc'},
  {sortKey: 'property:asc', value: 'PROPERTY_NAME_ASC', label: 'Property Asc'},
  {sortKey: 'property:desc', value: 'PROPERTY_NAME_DESC', label: 'Property Desc'},
  {sortKey: 'moveIn:asc', value: 'LEASE_MOVE_IN_DATE_ASC', label: 'Move In Asc'},
  {sortKey: 'moveIn:desc', value: 'LEASE_MOVE_IN_DATE_DESC', label: 'Move In Desc'},
  {sortKey: 'moveOut:asc', value: 'LEASE_MOVE_OUT_DATE_ASC', label: 'Move Out Asc'},
  {sortKey: 'moveOut:desc', value: 'LEASE_MOVE_OUT_DATE_DESC', label: 'Move Out Desc'},
  {sortKey: 'leaseType:asc', value: 'LEASE_TYPE_ASC', label: 'Lease Type Asc'},
  {sortKey: 'leaseType:desc', value: 'LEASE_TYPE_DESC', label: 'Lease Type Desc'},
]
const Residents = () => {
  const navigate = useNavigate()
  const {setQuery} = useContext(ExportTableContext)
  const [residents, setResidents] = useState<ResidentPreview[]>([])

  const {
    queryOptions,
    setQueryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    onChangeNumberOfItems,
  } = useQueryOptions<TResidentFilterFields>({
    page: 1,
    orderBy: ['LEASE_MOVE_OUT_DATE_ASC'],
    searchTerm: '',
    filters: {
      isPersonAccessActive: 'all',
    },
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    residents: accessesList,
    response: residentsResponse,
    variables: queryVariables,
    queryForDownloadTable,
  } = useResidentsData(debouncedSearchTerm, queryOptions, true)

  useEffect(() => {
    const initialRequestData = accessesList.map(resident => ({
      name: resident.personName,
      nameWithAvatar: <CellWithAvatar name={resident.personName} />,
      isActive: (
        <Badge size='sm' theme={resident?.isPersonAccessActive ? 'info' : 'danger'}>
          {resident?.isPersonAccessActive ? 'Active' : 'Inactive'}
        </Badge>
      ),
      property: resident.propertyName,
      building: resident.buildingName,
      unit: resident.unitNumber,
      unitId: resident.unitId,
      moveIn: toCommonDateFormat(resident.lockScheduleStartDt) || '—',
      moveOut: '—',
      renewal: '—',
      expiration: toCommonDateFormat(resident.lockScheduleEndDt) || '—',
      id: resident.personId,
      leaseId: Number(resident.leaseId),
    }))

    setResidents(initialRequestData)
  }, [accessesList])

  const onChangeSortOrder = (value: string) => {
    tableSort.setSortValue(value)
    upsertQueryOptions({orderBy: [value]})
  }

  const onChangeSearchQuery = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions({
        searchTerm,
        page: 1,
      })
    },
    [upsertQueryOptions],
  )

  const onPressPerson = useCallback(
    (person?: ResidentPreview) => {
      if (person?.id && person.unitId) {
        navigate(
          `/reports/people/residents/${person.id}/overview?unitId=${person.unitId}&leaseId=${person.leaseId}`,
        )
      }
    },
    [navigate],
  )

  const onPressRow = useCallback(
    (index: number) => {
      onPressPerson(residents[index])
    },
    [residents, onPressPerson],
  )

  const onSubmitFilter = useCallback(
    (filters: TResidentFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const dataForTableQuery = useCallback(async () => {
    try {
      const {data} = await queryForDownloadTable()

      const residents = data?.transactionalDb.allPortalPersonViews.nodes

      const tableData = residents.map(resident =>
        Object.values({
          name: resident.personName || '—',
          status: resident.isPersonAccessActive ? 'Active' : 'Inactive',
          property: resident.propertyName || '—',
          building: resident.buildingName || '—',
          unit: resident.unitNumber || '—',
          moveIn: toCommonDateFormat(resident.lockScheduleStartDt) || '—',
          moveOut: '—',
          renewal: '—',
          expiration: toCommonDateFormat(resident.lockScheduleEndDt) || '—',
        }),
      )

      tableData.unshift([
        'Name',
        'Status',
        'Property',
        'Building',
        'Unit',
        'Move In',
        'Move Out',
        'Renewal',
        'Expiration',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSort.value])

  const count =
    residentsResponse.data?.transactionalDb.allPortalPersonViews.totalCount || 0

  useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Residents table is empty...',
  })

  return (
    <People
      onChangeNumberOfItems={onChangeNumberOfItems}
      upsertQueryOptions={upsertQueryOptions}
      onTypeSearchField={onChangeSearchQuery}
      onPressRow={onPressRow}
      Filters={ResidentsFilters}
      queryOptions={queryOptions}
      tableProps={{
        order: tableSort.order,
        selectedColumn: tableSort.column,
        selectedColumnChange: tableSort.setSortColumn,
        rows: residents,
        columns: [
          {name: 'Name', key: 'nameWithAvatar', sortable: true},
          {name: 'Status', key: 'isActive', sortable: true},
          {name: 'Property', key: 'property', sortable: true},
          {name: 'Building', key: 'building', sortable: true},
          {name: 'Unit', key: 'unit', sortable: true},
          {name: 'Move In', key: 'moveIn', sortable: true},
          {name: 'Move Out', key: 'moveOut', sortable: true},
          {name: 'Renewal', key: 'renewal', sortable: true},
          {name: 'Expiration', key: 'expiration'},
        ],
      }}
      count={count}
      loading={residentsResponse.loading}
      className={'Residents'}
      testId={'ResidentsReports'}
      searchPlaceholder={'Search residents'}
      onChangeSortOrder={onChangeSortOrder}
      onSubmitFilter={onSubmitFilter}
      sortOptions={sortOptions}
    />
  )
}

export default Residents
