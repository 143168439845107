import {useQuery} from '@apollo/client'
import {
  TAllAccessPointsResponse,
  TAllAccessPointsVariables,
} from '../../data/graphql/queries/properties/types'
import {GET_ACCESS_POINTS} from '../../data/graphql/queries/properties'
import useToast from '../useToast'
import useUserAccess from '../useUserAccess'
import {useEffect, useState} from 'react'

type PropertyStructure = {
  propertyId: string
  propertyName: string
  buildingId: string
  buildingName: string

  unitId: string
  unitNumber: string
  floorPlanId: string
  unitType: string
}

export const usePropertiesStructures = () => {
  const {showToast} = useToast()
  const userAccess = useUserAccess()
  const [flattenData, setFlattenData] = useState<PropertyStructure[]>([])

  const query = useQuery<TAllAccessPointsResponse, TAllAccessPointsVariables>(
    GET_ACCESS_POINTS,
    {
      skip: !userAccess.properties?.length,
      variables: {
        filter: {
          propertyId: {
            in: userAccess.properties,
          },
        },
        buildingsFilter: {
          buildingId: {
            notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
          },
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Failed to fetch access points data',
          type: 'error',
        })
      },
    },
  )

  const properties = query.data?.transactionalDb.allProperties.nodes

  useEffect(() => {
    const flattenData: PropertyStructure[] = []

    if (!properties) {
      setFlattenData(flattenData)
      return
    }

    properties?.forEach(({propertyId, propertyName, buildingsByPropertyId}) => {
      buildingsByPropertyId.nodes.forEach(
        ({buildingId, buildingName, unitsByBuildingId}) => {
          unitsByBuildingId.nodes.forEach(unit => {
            flattenData.push({
              propertyId,
              propertyName,
              buildingId,
              buildingName,
              unitId: unit.unitId,
              unitNumber: unit.unitNumber,
              floorPlanId: unit.floorPlanId,
              unitType: unit.unitType.description,
            })
          })
        },
      )
    })

    setFlattenData(flattenData)
  }, [properties])

  return {
    loading: query.loading,
    data: properties,
    flattenData,
    response: query,
  }
}

export default usePropertiesStructures
