import {useQuery} from '@apollo/client'
import {useMemo} from 'react'
import {GET_PORTAL_PERSON_LIST} from '../../data/graphql/queries/people'
import {
  TPortalPersonListResponse,
  TPortalPersonListVariables,
} from '../../data/graphql/queries/people/types'
import useToast from '../useToast'
import useUserAccess from '../useUserAccess'

const usePortalPersonList = (variables?: Partial<TPortalPersonListVariables>) => {
  const {showToast} = useToast()
  const userAccess = useUserAccess()

  const allAccesses = useQuery<TPortalPersonListResponse, TPortalPersonListVariables>(
    GET_PORTAL_PERSON_LIST,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        ...variables,
        filter: {
          ...variables?.filter,
          buildingId: {
            notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
          },
          and: [
            ...(variables?.filter?.and || []),
            // TODO: propertyId for imported residents is 0, should be fixed on BE
            // {
            //   propertyId: {
            //     in: userAccess.properties,
            //   },
            // },
          ],
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Person List',
          type: 'error',
        })
      },
    },
  )

  const allAccessesList = useMemo(() => {
    return allAccesses.data?.transactionalDb.allPortalPersonViews.nodes || []
  }, [allAccesses.data?.transactionalDb.allPortalPersonViews.nodes])

  return [allAccessesList, allAccesses, allAccesses.loading] as const
}

export default usePortalPersonList
