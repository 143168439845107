import {useQuery} from '@apollo/client'
import {useCallback, useMemo} from 'react'
import {useParams} from 'react-router-dom'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import {GET_VENDOR} from '../../data/graphql/queries/people'
import {TVendorResponse, TVendorVariables} from '../../data/graphql/queries/people/types'
import usePersonAccesses from '../../hooks/data/usePersonAccesses'
import useToast from '../../hooks/useToast'
import VendorUserLayout from '../../layouts/VendorUserLayout'
import {TVendorUserLayoutSubmitData} from '../../layouts/VendorUserLayout/VendorUserLayout'
import CrashScreen from '../ScreenCrash/CrashScreen'
import useVendorUserOverview from './useVendorUserOverview'
import {InstallerContextProvider} from '../../layouts/VendorUserLayout/InstallerContext'

const VendorUserOverview = () => {
  const {showToast} = useToast()

  const {vendorId: vendorID, userId} = useParams()
  const vendorId = Number(vendorID)
  const personId = userId ? Number(userId) : 0
  const {data: person} = usePersonAccesses(personId)

  const {loading, onInstallerVendorSubmit, onServiceVendorSubmit} =
    useVendorUserOverview()

  const vendorResponse = useQuery<TVendorResponse, TVendorVariables>(GET_VENDOR, {
    variables: {
      vendorId,
    },
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Vendor Data',
        type: 'error',
      })
    },
  })

  const vendor = vendorResponse.data?.transactionalDb.vendorByVendorId

  const breadcrumbs = useMemo(() => {
    const result = [
      {name: 'People', url: '/people/'},
      {name: 'Vendors', url: '/people/vendors'},
      {name: vendor?.vendorName || 'Vendor', url: '/people/vendors/' + vendorId},
    ]

    if (person) {
      result.push({
        name: `${person.firstName} ${person.lastName}`,
        url: `/people/vendors/${vendorId}/user/${personId}}`,
      })
    }

    return result
  }, [person, personId, vendor?.vendorName, vendorId])

  const onSubmit = useCallback(
    (input: TVendorUserLayoutSubmitData) => {
      if (input.vendorType === 'service') {
        onServiceVendorSubmit(input)
      } else {
        onInstallerVendorSubmit(input)
      }
    },
    [onServiceVendorSubmit, onInstallerVendorSubmit],
  )

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <InstallerContextProvider>
        <VendorUserLayout
          isEditing
          loading={loading || vendorResponse.loading}
          userId={personId}
          vendorId={vendorId}
          breadCrumbs={breadcrumbs}
          onSubmit={onSubmit}
        />
      </InstallerContextProvider>
    </ErrorBoundary>
  )
}

export default VendorUserOverview
