import {TCommonError, YaleUserType} from './../../types'

export enum AccessScheduleTypes {
  ALWAYS = 'ALWAYS',
  TEMPORARY = 'TEMPORARY',
  RECURRING = 'RECURRING',
  UNKNOWN = 'UNKNOWN',
}

export type TPinAccess = TPermanentPin | TTemporaryPin | TRecurringPin

export type TPermanentPin = {
  accessType: 'ALWAYS'
}

export type TTemporaryPin = {
  accessType: 'TEMPORARY'
  accessTimes: string
}

export type TRecurringPin = {
  accessType: 'RECURRING'
  accessTimes: string
  accessRecurrence: string
}

export type TGrantFullAccessToLocksVariables = {
  input: {
    userId: string
    deviceMaker: string
    lockAccesses: {
      yaleDeviceId: string
      userType: 'USER' | 'MANAGER'
    }[]
  }
}

export type TRevokeAllLocksAccessesResponse = {
  lock: {
    revokeAllLockAccess: {
      failed: {
        reason: string
        userType: 'SUPERUSER' | 'MANAGER' | 'USER' | 'LIMITED' | 'UNVERIFIED'
        yaleLockId: string
      }[]
      revoked: {
        userType: 'SUPERUSER' | 'MANAGER' | 'USER' | 'LIMITED' | 'UNVERIFIED'
        yaleLockId: string
      }[]
    }
  }
}

export type TRevokeAllLockAccessesVariable = {
  input: {
    deviceMaker: 'YALE'
    yaleUserId: string
  }
}

export type TGrantFullAccessToLocksResponse = {
  lock: {
    grantFullAccessToLocks:
      | {
          failed: {
            userType: string
            yaleDeviceId: string
          }[]
          granted: {
            userType: string
            yaleDeviceId: string
          }[]
        }
      | TCommonError
  }
}

export type TGrantLimitedAccessToLocksResponse = {
  lock: {
    grantLimitedAccessToLocks:
      | {
          created: {
            accessType: 'TEMPORARY' | 'RECURRING'
            yaleDeviceId: string
            ruleId: string
          }[]
          failed: {
            accessType: 'TEMPORARY' | 'RECURRING'
            yaleDeviceId: string
            reason: string
          }[]
        }
      | TCommonError
  }
}

export type TRevokeAccessToLocksResponse = {
  lock: {
    revokeAccessToLocks:
      | {
          failed: {
            reason: string
            userType: 'SUPERUSER' | 'MANAGER' | 'USER' | 'LIMITED' | 'UNVERIFIED'
            yaleDeviceId: string
          }[]
          revoked: {
            userType: 'SUPERUSER' | 'MANAGER' | 'USER' | 'LIMITED' | 'UNVERIFIED'
            yaleDeviceId: string
          }[]
        }
      | TCommonError
  }
}

export type TLockLimitedAccess = {
  accessType?: Exclude<AccessScheduleTypes, AccessScheduleTypes.ALWAYS>
  deviceId: string
  endTime: string
  schedule: string
  startTime: string
}

export type TGrantLimitedAccessToLocksVariables = {
  input: {
    userId: string
    deviceMaker: string
    lockAccessRules: TLockLimitedAccess[]
  }
}

export type TRevokeAccessToLocksVariables = {
  input: {
    yaleUserId: string
    deviceMaker: string
    yaleLockIds: Array<string | number>
  }
}

export type TLockPin = TPinAccess & {
  deviceId: string
  yaleUserType: YaleUserType
}

export type TSetLockPinsVariables = {
  input: {
    deviceMaker: string
    userId: string
    locksToSet: TLockPin[]
  }
}

export type TSetLockPinsResponse = {
  lock: {
    setLockPins:
      | {
          pin: string
          submitted: {
            deviceId: string
            transactionId: string
            accessType: AccessScheduleTypes
          }[]
          failed: {
            reason: string
          }[]
        }
      | TCommonError
  }
}

export type TCancelAsyncTransactionResponse = {
  utility: {
    cancelUnsuccessfulAsyncTransaction:
      | {
          softDeletedAsyncTransactionId: number
        }
      | TCommonError
  }
}

export type TCancelAsyncTransactionVariables = {
  input: {
    asyncTransactionId: number
  }
}

export type TRevokeCommonAreaAccessResponse = {
  lock: {
    revokeAccessFromCommonAreaLocks: {success: boolean} | TCommonError
  }
}

export type TRevokeCommonAreaAccessVariables = {
  input: {
    deviceMaker: string
    personId: number
    propertyId: number
  }
}

export type TGrantCommonAreaAccessResponse = {
  lock: {
    grantAccessToCommonAreaLocks: {
      userId: string
      accessCode?: string
    }
  }
}

export type TGrantCommonAreaAccessVariables = {
  input: {
    deviceMaker: string
    personId: number
    propertyId: number
    effectiveFrom?: string
    effectiveTo?: string
    enableAppAccess: boolean
  }
}
