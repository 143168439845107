import {useFormik} from 'formik'
import {useEffect, useMemo} from 'react'

import {useQuery} from '@apollo/client'
import {addHours} from 'date-fns'
import {
  TAccessScheduleFields,
  TAccessType,
} from '../../components/AccessSchedule/AccessSchedule'
import {
  convertHoursTo24,
  getAccessObject,
  getUserSchedule,
  parseUserSchedule,
} from '../../functions/lock.functions'
import {GET_PERSON_ACCESSES_BY_ID} from '../../data/graphql/queries/people'
import {
  AccessMiscInfo,
  TPersonAccessesByIdResponse,
  TPersonAccessesByIdVariables,
} from '../../data/graphql/queries/people/types'
import useToast from '../../hooks/useToast'
import useUnitsDevices from '../../hooks/data/useAccessPointsDevices'
import {DeviceTypes, TAccessTypeId} from '../../data/graphql/queries/common/types'
import {
  areSchedulesEqual,
  dateToDateTimeObject,
  flattenAccessPoints,
} from '../../functions'
import {AccessScheduleTypes} from '../../data/graphql/mutations/lock/types'
import {YaleUserType} from '../../data/graphql/types'
import {isCommonAreaBuilding} from '../../functions/devices.function'

export type TServiceCommonAreaAccess = {
  propertyId: string
  startDate?: string
  endDate?: string
  accessType: TAccessType
}

export type TServiceTaskAccess = {
  installedDeviceId: number
  lockId: string
  accessLevel: TAccessType
  access?: {
    schedule?: string
    accessType?: AccessScheduleTypes
    deviceId?: string
    endTime?: string
    startTime?: string
  }
}

type TUnitId = string
type TBuildingId = string
type TPropertyId = string

type TUnit = TAccessScheduleFields | null

type TBuilding = {
  units: Record<TUnitId, TUnit | null>
  buildingName: string
  data?: TAccessScheduleFields
}

type TFields = {
  isVendorIdentityCreated: boolean
  schedule: TAccessScheduleFields
  accessPoints: Record<
    TPropertyId,
    {
      data?: TAccessScheduleFields
      buildings: Record<TBuildingId, TBuilding>
    }
  >
}

type TSubmitDataResponse = Promise<{
  lockAccessesRequested: TServiceTaskAccess[]
  lockAccessesDeleted: TServiceTaskAccess[]
  commonAreaPropertyAccessesRequested: TServiceCommonAreaAccess[]
  commonAreaPropertyAccessesDeleted: string[]
}>

const useScheduleAccessPoints = (data: {userId?: string; limited?: boolean}) => {
  const {showToast} = useToast()
  const unitsDevices = useUnitsDevices(
    [DeviceTypes.YALE_622, DeviceTypes.BRIVO, DeviceTypes.YALE_ASSURE_2],
    {
      isDeviceInstalled: true,
    },
  )

  const personResponse = useQuery<
    TPersonAccessesByIdResponse,
    TPersonAccessesByIdVariables
  >(GET_PERSON_ACCESSES_BY_ID, {
    skip: !data.userId,
    fetchPolicy: 'no-cache',
    variables: {
      personId: data.userId ? +data.userId : 0,
      condition: {
        isActive: true,
        isDeleted: false,
      },
    },
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Person Accesses Data',
        type: 'error',
      })
    },
  })

  const person = personResponse.data?.transactionalDb.personByPersonId
  const isVendorIdentityCreated = !!person?.isIdentityCreated

  const getAccessScheduleType = (miscInfo?: AccessMiscInfo): AccessScheduleTypes => {
    if (
      miscInfo?.lockAccess?.yaleUserType === YaleUserType.MANAGER ||
      miscInfo?.lockAccess?.yaleUserType === YaleUserType.USER
    ) {
      return AccessScheduleTypes.ALWAYS
    } else if (miscInfo?.lockAccess?.pin?.accessType) {
      return miscInfo?.lockAccess?.pin?.accessType as AccessScheduleTypes
    } else if (miscInfo?.lockAccess?.limitingRule?.accessType) {
      return miscInfo?.lockAccess?.limitingRule?.accessType as AccessScheduleTypes
    }

    return AccessScheduleTypes.UNKNOWN
  }

  const remoteSchedule = useMemo(() => {
    if (!person?.personAccessesByPersonId?.nodes) {
      return {}
    }

    return person.personAccessesByPersonId.nodes.reduce<TFields['accessPoints']>(
      (result, current) => {
        const {
          propertyId,
          buildingId,
          buildingByBuildingId,
          unitId,
          miscInfo,
          personAccessTypeId,
        } = current
        const isPinOnlyAccess = +personAccessTypeId === TAccessTypeId.P

        const accessType = isPinOnlyAccess ? 'pin' : 'app'

        if (!result[propertyId]) {
          result[propertyId] = {
            buildings: {},
          }
        }

        if (!result[propertyId].buildings[buildingId]) {
          result[propertyId].buildings[buildingId] = {
            buildingName: buildingByBuildingId.buildingName,
            units: {},
          }
        }

        if (isCommonAreaBuilding(buildingByBuildingId)) {
          const effectiveFrom = miscInfo?.commonAreaLockAccess?.effectiveFrom
          const effectiveTo = miscInfo?.commonAreaLockAccess?.effectiveTo

          const startDate = effectiveFrom ? new Date(effectiveFrom) : new Date()
          const endDate = effectiveTo ? new Date(effectiveTo) : addHours(startDate, 1)

          result[propertyId].buildings[buildingId]!.data = {
            accessType,
            accessScheduleType: effectiveTo
              ? AccessScheduleTypes.TEMPORARY
              : AccessScheduleTypes.ALWAYS,
            startDate: startDate,
            endDate: endDate,
            weekDays: [],
            startTime: dateToDateTimeObject(startDate),
            endTime: dateToDateTimeObject(addHours(startDate, 1)),
          }
          result[propertyId].buildings[buildingId]!.units[unitId] = null
        } else {
          const accessScheduleType = getAccessScheduleType(miscInfo)

          const pinData = getAccessObject({
            accessType: accessScheduleType,
            accessTimes: miscInfo?.lockAccess?.pin?.accessTimes || '',
            accessRecurrence: miscInfo?.lockAccess?.pin?.accessRecurrence || '',
          })

          const parsedPin =
            miscInfo?.lockAccess?.limitingRule?.schedule ||
            getUserSchedule({
              accessType: accessScheduleType,
              days: pinData.days || [],
              endDateTime: pinData.endDateTime,
              startDateTime: pinData.startDateTime,
            })

          const schedule = parseUserSchedule(parsedPin)

          result[propertyId].buildings[buildingId]!.units[unitId] = {
            accessType,
            accessScheduleType: accessScheduleType,
            startDate: schedule?.startDateTime || new Date(),
            endDate: schedule?.endDateTime || addHours(new Date(), 1),
            weekDays: schedule?.days || [],
            startTime: dateToDateTimeObject(schedule?.startDateTime || new Date()),
            endTime: dateToDateTimeObject(
              schedule?.endDateTime || addHours(new Date(schedule.startDateTime), 1),
            ),
          }
        }

        return result
      },
      {},
    )
  }, [person?.personAccessesByPersonId?.nodes])

  const getUserAccess = (schedule?: TAccessScheduleFields | null) => {
    if (!schedule) {
      return null
    }

    const startDateTime = schedule.startDate
    const endDateTime = schedule.endDate

    if (schedule.accessScheduleType === AccessScheduleTypes.RECURRING) {
      startDateTime.setHours(convertHoursTo24(schedule.startTime))
      startDateTime.setMinutes(schedule.startTime.minutes)
      endDateTime?.setHours(convertHoursTo24(schedule.endTime))
      endDateTime?.setMinutes(schedule.endTime.minutes)
    }

    const yaleSchedule = schedule
      ? getUserSchedule({
          accessType: schedule.accessScheduleType,
          days: schedule.weekDays,
          endDateTime: endDateTime,
          startDateTime: startDateTime,
        })
      : ''

    return {
      accessLevel: schedule.accessType,
      schedule: yaleSchedule,
      accessType: schedule.accessScheduleType,
      endTime: endDateTime?.toISOString(),
      startTime: startDateTime?.toISOString(),
    }
  }

  const {values, errors, touched, setFieldValue, handleChange, handleBlur, ...form} =
    useFormik<TFields>({
      initialValues: {
        accessPoints: {},
        isVendorIdentityCreated: false,
        schedule: {
          accessType: 'app',

          accessScheduleType: data?.limited
            ? AccessScheduleTypes.TEMPORARY
            : AccessScheduleTypes.ALWAYS,
          startDate: new Date(),
          endDate: addHours(new Date(), 1),
          weekDays: [],

          startTime: {
            hours: 9,
            minutes: 0,
            dayTime: 'am',
          },
          endTime: {
            hours: 11,
            minutes: 0,
            dayTime: 'am',
          },
        },
      },
      onSubmit: (
        values,
      ): Promise<{
        lockAccessesRequested: TServiceTaskAccess[]
        lockAccessesDeleted: TServiceTaskAccess[]
        commonAreaPropertyAccessesRequested: TServiceCommonAreaAccess[]
        commonAreaPropertyAccessesDeleted: string[]
      }> => {
        const lockAccessesRequested: TServiceTaskAccess[] = []
        const lockAccessesDeleted: TServiceTaskAccess[] = []
        const commonAreasAccess = getCommonAreaAccesses(values.accessPoints)

        const localAccessPoints = flattenAccessPoints(values.accessPoints)
        const remoteAccessPoints = flattenAccessPoints(remoteSchedule)

        const diff = getUnitsDiff(remoteAccessPoints, localAccessPoints)

        const unitsToTakeAction = Array.from(
          new Set([...Object.keys(diff.added), ...Object.keys(diff.deleted)]),
        )

        unitsToTakeAction.forEach(unitId => {
          const unitDevices = Object.values({
            ...unitsDevices.data[unitId][DeviceTypes.YALE_622],
            ...unitsDevices.data[unitId][DeviceTypes.BRIVO],
            ...unitsDevices.data[unitId][DeviceTypes.YALE_ASSURE_2],
          })

          unitDevices.forEach(({installedDeviceId, lockId}) => {
            if (!lockId || !installedDeviceId) {
              return
            }

            if (diff.deleted[unitId]) {
              lockAccessesDeleted.push(
                createServiceTaskAccess(lockId, installedDeviceId, diff.deleted[unitId]),
              )
            }

            if (diff.added[unitId]) {
              lockAccessesRequested.push(
                createServiceTaskAccess(lockId, installedDeviceId, diff.added[unitId]),
              )
            }
          })
        })

        return Promise.resolve({
          lockAccessesRequested,
          lockAccessesDeleted,
          commonAreaPropertyAccessesRequested: commonAreasAccess.requested,
          commonAreaPropertyAccessesDeleted: commonAreasAccess.deleted,
        })
      },
    })

  const createServiceTaskAccess = (
    lockId: string,
    installedDeviceId: string,
    schedule: TAccessScheduleFields,
  ): TServiceTaskAccess => {
    const access = getUserAccess(schedule)

    return {
      lockId,
      installedDeviceId: +installedDeviceId,
      accessLevel: access?.accessLevel || 'pin',
      access: {
        schedule: access?.schedule,
        accessType: access?.accessType,
        deviceId: lockId,
        endTime: access?.endTime,
        startTime: access?.startTime,
      },
    }
  }

  const getCommonAreaAccesses = (
    accessPoints: Record<
      string,
      {
        data?: TAccessScheduleFields | undefined
        buildings: Record<TBuildingId, TBuilding>
      }
    >,
  ) => {
    const requested: TServiceCommonAreaAccess[] = []
    const deleted: string[] = []

    Object.keys(accessPoints).forEach(propertyId => {
      const property = accessPoints[propertyId]

      const commonAreaBuilding = Object.values(property.buildings).find(building =>
        isCommonAreaBuilding(building),
      )
      const remoteCommonAreaSchedule = Object.values(
        remoteSchedule?.[propertyId]?.buildings || {},
      ).find(building => isCommonAreaBuilding(building))?.data

      const isCommonAreaBuildingAccessGranted = !!commonAreaBuilding

      const localCommonAreaSchedule = isCommonAreaBuildingAccessGranted
        ? commonAreaBuilding?.data || property.data
        : null

      if (remoteCommonAreaSchedule && !localCommonAreaSchedule) {
        deleted.push(propertyId)
      } else if (localCommonAreaSchedule && !remoteCommonAreaSchedule) {
        requested.push({
          propertyId,
          accessType: localCommonAreaSchedule.accessType,
          startDate: localCommonAreaSchedule.startDate?.toISOString(),
          endDate: localCommonAreaSchedule.endDate?.toISOString(),
        })
      } else if (
        remoteCommonAreaSchedule &&
        localCommonAreaSchedule &&
        !areSchedulesEqual(remoteCommonAreaSchedule, localCommonAreaSchedule)
      ) {
        deleted.push(propertyId)
        requested.push({
          propertyId,
          accessType: localCommonAreaSchedule.accessType,
          startDate: localCommonAreaSchedule.startDate?.toISOString(),
          endDate: localCommonAreaSchedule.endDate?.toISOString(),
        })
      }
    })

    return {requested, deleted}
  }

  const getUnitsDiff = (
    remoteUnits: Record<TUnitId, TAccessScheduleFields>,
    localUnits: Record<TUnitId, TAccessScheduleFields>,
  ) => {
    const diff: {
      deleted: Record<TUnitId, TAccessScheduleFields>
      added: Record<TUnitId, TAccessScheduleFields>
    } = {
      deleted: {},
      added: {},
    }

    const localUnitsIds = Object.keys(localUnits)
    const remoteUnitsIds = Object.keys(remoteUnits)
    const allUnitIds = Array.from(new Set([...localUnitsIds, ...remoteUnitsIds]))

    allUnitIds.forEach(unitId => {
      if (remoteUnits[unitId] && !localUnits[unitId]) {
        diff.deleted[unitId] = remoteUnits[unitId]
      }

      if (localUnits[unitId] && !remoteUnits[unitId]) {
        diff.added[unitId] = localUnits[unitId]
      }

      if (localUnits[unitId] && remoteUnits[unitId]) {
        const remoteUnitSchedule = remoteUnits[unitId]
        const localUnitSchedule = localUnits[unitId]

        if (
          localUnitSchedule?.accessType === 'pin' &&
          localUnitSchedule?.accessType !== remoteUnitSchedule?.accessType
        ) {
          if (remoteUnitSchedule) {
            diff.deleted[unitId] = remoteUnitSchedule
          }
          diff.added[unitId] = localUnitSchedule
        } else if (
          remoteUnitSchedule &&
          localUnitSchedule &&
          !areSchedulesEqual(remoteUnitSchedule, localUnitSchedule)
        ) {
          diff.added[unitId] = localUnitSchedule
        }
      }
    })

    // if (remoteUnits?.units) {
    //   diffArrays(remoteUnitsIds, localUnitsIds).forEach(id => {
    //     diff.deleted[id] = remoteUnits.units[id]
    //   })
    // }

    // if (localUnits?.units) {
    //   diffArrays(localUnitsIds, remoteUnitsIds).forEach(id => {
    //     diff.added[id] = localUnits.units[id]
    //   })
    // }

    // remoteUnitsIds.forEach(unitId => {
    //   const prevSchedule = remoteUnits?.units[unitId]
    //   const newSchedule = localUnits?.units[unitId]

    //   if (
    //     newSchedule?.accessType === 'pin' &&
    //     newSchedule?.accessType !== prevSchedule?.accessType
    //   ) {
    //     if (prevSchedule) {
    //       diff.deleted[unitId] = prevSchedule
    //     }

    //     diff.updated[unitId] = newSchedule
    //   } else if (
    //     prevSchedule &&
    //     newSchedule &&
    //     !areSchedulesEqual(prevSchedule, newSchedule)
    //   ) {
    //     diff.updated[unitId] = newSchedule
    //   }
    // })

    return diff
  }

  const getBuildingsDiff = (
    prevBuilding: TBuilding | null,
    newBuilding: TBuilding | null,
  ) => {
    const diff: {
      deleted: Record<TUnitId, TUnit>
      added: Record<TUnitId, TUnit>
      updated: Record<TUnitId, TUnit>
    } = {
      deleted: {},
      added: {},
      updated: {},
    }

    const prevUnitsIds = Object.keys(prevBuilding?.units || {})
    const newUnitsIds = Object.keys(newBuilding?.units || {})

    if (prevBuilding?.units) {
      diffArrays(prevUnitsIds, newUnitsIds).forEach(id => {
        diff.deleted[id] = prevBuilding.units[id]
      })
    }

    if (newBuilding?.units) {
      diffArrays(newUnitsIds, prevUnitsIds).forEach(id => {
        diff.added[id] = newBuilding.units[id]
      })
    }

    prevUnitsIds.forEach(unitId => {
      const prevSchedule = prevBuilding?.units[unitId]
      const newSchedule = newBuilding?.units[unitId]

      if (
        newSchedule?.accessType === 'pin' &&
        newSchedule?.accessType !== prevSchedule?.accessType
      ) {
        if (prevSchedule) {
          diff.deleted[unitId] = prevSchedule
        }

        diff.updated[unitId] = newSchedule
      } else if (
        prevSchedule &&
        newSchedule &&
        !areSchedulesEqual(prevSchedule, newSchedule)
      ) {
        diff.updated[unitId] = newSchedule
      }
    })

    return diff
  }

  const diffArrays = (sourceArr?: string[], excludeArray?: string[]): string[] => {
    if (!excludeArray?.length || !sourceArr?.length) {
      return sourceArr || []
    }

    return sourceArr.filter(value => !excludeArray.includes(value))
  }

  const submitForm = () => {
    return form.submitForm() as Promise<TSubmitDataResponse>
  }

  useEffect(() => {
    const copy = structuredClone(remoteSchedule)
    setFieldValue('accessPoints', copy)
    setFieldValue('isVendorIdentityCreated', isVendorIdentityCreated)
  }, [isVendorIdentityCreated, remoteSchedule])

  return {
    values,
    errors,
    touched,
    handleBlur,
    submitForm,
    handleChange,
    setFieldValue,
  }
}

export default useScheduleAccessPoints
