// components/Login/Login.tsx

import {FC, FormEvent, useState, useContext, useEffect} from 'react'
import {useLazyQuery} from '@apollo/client'
import {getAuth, signInAnonymously, signInWithEmailAndPassword} from 'firebase/auth'
import './Login.style.scss'
import {randomString, formatIdentityError} from '../../functions'
import InputText from '../../components/InputText/InputText'
import ButtonRow from '../../components/ButtonRow'
import Button from '../../components/Button'
import {AuthContext} from '../../contexts/AuthContext'
import {GET_INFO_FOR_LOGIN} from '../../data/graphql/queries/auth'
import {
  TInfoForLoginReponse,
  TInfoForLoginVariables,
} from '../../data/graphql/queries/auth/types'
import {TPersonTypeCode} from '../../data/graphql/queries/common/types'

export interface LoginFormProps {
  id?: string
  value?: any
  valueChange?: any
}

const defaultProps: LoginFormProps = {
  id: randomString(),
}

const LoginForm: FC<LoginFormProps> = (props: LoginFormProps) => {
  props = {...defaultProps, ...props}
  const auth = getAuth()
  const {user, setUser} = useContext(AuthContext)
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [formState, setFormState] = useState<any>({
    email: '',
    password: '',
    remember: true,
  })

  useEffect(() => {
    setPageLoading(true)

    const anonymousSignIn = async () => {
      const anonymousUser = await signInAnonymously(auth)
      setUser({...anonymousUser.user})
    }

    anonymousSignIn()
    setPageLoading(false)
  }, [])

  const [getInfoForLogin, {loading, error, data}] = useLazyQuery<
    TInfoForLoginReponse,
    TInfoForLoginVariables
  >(GET_INFO_FOR_LOGIN, {
    notifyOnNetworkStatusChange: true,
    onCompleted: response => {
      const success = response?.utility?.getInfoForLogin?.__typename

      if (success === 'GetInfoForLoginSuccess') {
        const tenantId = response?.utility?.getInfoForLogin?.idpTenantId
        const personType = response?.utility?.getInfoForLogin?.personTypeCode

        loginUser(tenantId, personType)
      } else {
        setErrorMessage('Error logging in!')
        setPageLoading(false)
      }
    },
    onError: error => {
      setErrorMessage('Error logging in!')
      setPageLoading(false)
    },
  })

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrorMessage('')
    setPageLoading(true)

    try {
      auth.tenantId = process.env.REACT_APP_GOOGLE_DEFAULT_IDP_TENANT || null

      if (
        auth.currentUser?.isAnonymous &&
        auth.currentUser?.tenantId !== process.env.REACT_APP_GOOGLE_DEFAULT_IDP_TENANT
      ) {
        await auth.signOut()
      }

      await signInAnonymously(auth)

      getInfoForLogin({
        variables: {input: {email: formState.email}},
      })
    } catch (error: any) {
      const errorMessageCode = error.code
      setErrorMessage(formatIdentityError(errorMessageCode))
      setPageLoading(false)
    }
  }

  const loginUser = async (tenantId: string | null, personType: string | null) => {
    if (personType !== TPersonTypeCode.E && personType !== TPersonTypeCode.DA) {
      return setErrorMessage('Permission denied!')
    }

    auth.tenantId = tenantId
    const {email, password} = formState
    const isDevMode = window.location.href.includes('devMode=true')

    try {
      await signInWithEmailAndPassword(auth, email, password)

      localStorage.setItem('devMode', JSON.stringify(!!isDevMode))
    } catch (error: any) {
      const {code} = error
      const formattedError = formatIdentityError(code)

      setErrorMessage(formattedError)
    }

    setPageLoading(false)
  }

  const handleChange = (name: string, value: string | number) => {
    setFormState({...formState, [name]: value})
  }

  // const isDisabled = (): boolean => {
  //   return !(
  //     validateEmail(formState.email as string) &&
  //     validatePassword(formState.password as string)
  //   )
  // }

  return (
    <>
      {pageLoading && !errorMessage ? (
        <div>Loading...</div>
      ) : (
        <form
          id={props.id}
          className={'Login-form'}
          data-testid={'LoginForm'}
          onSubmit={e => {
            handleSubmit(e)
          }}
        >
          <InputText
            id={'login-email'}
            type={'email'}
            label={'Email'}
            placeholder={'Email Address'}
            value={formState.email}
            onValueChange={v => handleChange('email', v)}
          />
          <InputText
            id={'login-password'}
            type={'password'}
            label={'Password'}
            placeholder={'Password'}
            value={formState.password}
            suffixIcon='eye'
            onValueChange={v => handleChange('password', v)}
          />
          {errorMessage && (
            <div className={'color-red error-message'}>{errorMessage}</div>
          )}
          <ButtonRow>
            <Button
              id='login-btn'
              size='lg'
              type={'submit'}
              theme={'default'}
              width={'block'}
            >
              Login
            </Button>
            <Button
              size='sm'
              type={'button'}
              theme={'link'}
              width={'block'}
              to={'forgot-password'}
            >
              Forgot Password?
            </Button>
          </ButtonRow>
        </form>
      )}
    </>
  )
}

export default LoginForm
