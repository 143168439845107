import {useQuery} from '@apollo/client'
import {
  TUnitDetailsRespone,
  TUnitDetailsVariables,
} from '../../data/graphql/queries/properties/types'
import {GET_UNIT_DETAILS} from '../../data/graphql/queries/properties'
import {useParams} from 'react-router-dom'
import {
  DeviceTypes,
  AccessTypes,
  TAccessTypeCode,
  TPersonTypeCode,
} from '../../data/graphql/queries/common/types'
import useToast from '../../hooks/useToast'
import {useCallback, useMemo} from 'react'

const activeCondition = {
  isActive: true,
  isDeleted: false,
}

const useUnitDetails = () => {
  const params = useParams()
  const {showToast} = useToast()
  const unitId = Number(params.unitId)
  const propertyId = Number(params.propertyId)

  const unitQuery = useQuery<TUnitDetailsRespone, TUnitDetailsVariables>(
    GET_UNIT_DETAILS,
    {
      variables: {
        unitId: unitId,
        propertyId: propertyId,
        leaseCondition: {
          ...activeCondition,
          isExpired: false,
        },
        thermostatsCondition: {
          ...activeCondition,
          deviceTypeId: DeviceTypes.NEST_E,
        },
        locksFilter: {
          isActive: {
            equalTo: true,
          },
          isDeleted: {
            equalTo: false,
          },
          deviceTypeId: {
            in: [DeviceTypes.YALE_622, DeviceTypes.YALE_ASSURE_2],
          },
        },
        residentsCondition: {
          ...activeCondition,
          unitId: Number(unitId),
        },
        personAccessesCondition: {
          ...activeCondition,
          unitId: Number(unitId),
          personAccessTypeId: AccessTypes.R,
        },

        guestsFilter: {
          isPersonAccessActive: {
            equalTo: true,
          },
          unitId: {
            equalTo: unitId,
          },
          and: [
            {
              or: [
                {
                  personAccessCode: {
                    equalTo: TAccessTypeCode.G,
                  },
                },
                {
                  personAccessCode: {
                    equalTo: TAccessTypeCode.P,
                  },
                },
              ],
            },

            {
              or: [
                {
                  personType: {
                    equalTo: TPersonTypeCode.R,
                  },
                },
                {
                  personType: {
                    equalTo: TPersonTypeCode.G,
                  },
                },
              ],
            },
          ],
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Unit Data',
          type: 'error',
        })
      },
    },
  )

  const getUnitAddress = useCallback(() => {
    const address = unitQuery.data?.db?.property?.address

    if (!address) {
      return ''
    }

    return `${address?.address1} - ${address?.city}, ${address?.state?.code} ${address?.zipCode}`
  }, [unitQuery.data?.db?.property?.address])

  const data = useMemo(() => {
    const response = unitQuery.data?.db
    const residentsData: TUnitDetailsRespone['db']['residents']['nodes'] =
      response?.residents?.nodes || []
    const guestsData: TUnitDetailsRespone['db']['guests']['nodes'] =
      response?.guests?.nodes || []

    const isLeaseActive = (
      lease: TUnitDetailsRespone['db']['residents']['nodes'][number]['lease'],
    ) => {
      if (!lease) return false

      const {isActive, isDeleted, isExpired, type, moveOutDate} = lease

      if (type?.code === 'MO') {
        return false
      } else if (moveOutDate && new Date(moveOutDate) < new Date()) {
        return false
      }

      return isActive && !isDeleted && !isExpired
    }

    const residents = residentsData
      .filter(({lease}) => isLeaseActive(lease))
      .map(({person, lease}) => ({
        leaseId: lease?.leaseId,
        unitId: unitId.toString(),
        personId: person.personId,
        personName: `${person.firstName} ${person.lastName}`,
        personAccessId: person.accesses.nodes[0]?.personAccessId,
      }))

    const guests = guestsData.map(person => ({
      unitId: unitId.toString(),
      personId: person.personId,
      personName: person.personName,
      personAccessId: person.personAccessId,
    }))

    const unit = response?.unit
      ? {
          number: response.unit.unitNumber,
          address: getUnitAddress(),
        }
      : null

    return {
      unit,
      guests,
      residents,
      property: response?.property,
      locks: response?.unit?.locks,
      thermostats: response?.unit?.thermostats,
      leaseholder: response?.unit?.leases.nodes[0]?.leaseholder,
    }
  }, [getUnitAddress, unitId, unitQuery.data?.db])

  return {
    data,
    loading: unitQuery.loading,
  }
}

export default useUnitDetails
