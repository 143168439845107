import {useCallback, useState} from 'react'
import useToast from '../../hooks/useToast'
import ConfirmModal from '../ConfirmModal'
import Button from '../Button'
import {useMutation} from '@apollo/client'
import {DEACTIVATE_PERSON} from '../../data/graphql/mutations/people'
import {
  TDeactivatePersonResponse,
  TDeactivatePersonVariables,
} from '../../data/graphql/mutations/people/types'

interface DeactivatePersonButtonProps {
  personId: number
  className?: string
  onRevoke?: () => void
}

const useDeactivatePerson = (personId: string | number) => {
  const {showErrorToast, showInfoToast} = useToast()
  const [deactivate, result] = useMutation<
    TDeactivatePersonResponse,
    TDeactivatePersonVariables
  >(DEACTIVATE_PERSON, {
    variables: {
      input: {
        personId: Number(personId),
      },
    },
    onError() {
      showErrorToast('Deactivate person error', 'Failed to deactive selected person')
    },
    onCompleted() {
      showInfoToast('Deactivate person', 'Person has been successfully deactivated')
    },
  })

  return {
    loading: result.loading,
    deactivate,
  }
}

const DeactivatePersonButton = ({
  className,
  personId,
  onRevoke,
}: DeactivatePersonButtonProps) => {
  const {showToast} = useToast()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const {loading, deactivate} = useDeactivatePerson(personId)

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
  }, [])

  const handleRevokeConfirm = useCallback(async () => {
    try {
      await deactivate()
      onRevoke?.()
    } catch (e) {
      showToast({
        type: 'error',
        message: 'Failed to revoke user accesses',
      })
    }

    closeModal()
  }, [closeModal, deactivate, showToast])

  const openModal = useCallback(
    e => {
      e.stopPropagation()
      setIsOpenModal(true)
    },
    [setIsOpenModal],
  )

  return (
    <div className={className}>
      <ConfirmModal
        isOpen={isOpenModal}
        title={'Deactive person'}
        message={
          "This action will delete the person's Apthub account and all their access. Are you sure you want to proceed?"
        }
        confirmLabel={loading ? 'Loading...' : 'Confirm'}
        onConfirm={handleRevokeConfirm}
        onCancel={closeModal}
      />

      <Button onClick={openModal} theme='danger' size='sm'>
        {loading ? 'Loading...' : 'Deactivate'}
      </Button>
    </div>
  )
}

export default DeactivatePersonButton
