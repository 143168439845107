import {useCallback, useEffect, useMemo} from 'react'
import {useQuery} from '@apollo/client'

import useToast from './useToast'
import {GET_ACTIVE_WORK_ASSIGMENTS} from '../data/graphql/queries/devices'
import {
  TGetAllDeviceWorkAssignmentsResponse,
  TGetAllDeviceWorkAssignmentsVariables,
} from '../data/graphql/queries/devices/types'
import {TAccessPointsValue} from '../layouts/VendorUserLayout/useAccessPoints'
import {TDevices} from '../layouts/VendorUserLayout/useDevicesAccessPoints'
import {getDeviceItemCheckboxId} from '../functions/devices.function'
import {DeviceClassTypes} from '../data/graphql/queries/common/types'
import usePropertyDevices from '../components/PropertyDevices/usePropertyDevices'
import useUserAccess from './useUserAccess'
import {TDeviceItem} from '../layouts/VendorUserLayout/InstallerAccessPoints/InstallerAccessPoints'
import {arrayToObject} from '../functions'
import {getInventoryId} from '../functions/lock.functions'
import useUnavailableDevices from './data/useUnavailableDevices'

export type TWorkAssignment =
  TGetAllDeviceWorkAssignmentsResponse['transactionalDb']['assignments']['nodes'][0]

const deviceDescription = {
  [DeviceClassTypes.LOCK]: 'Lock',
  [DeviceClassTypes.THERMOSTAT]: 'Thermostat',
}

const useVendorAssignments = (assignedVendorId: number | undefined) => {
  const {showToast} = useToast()
  const userAccess = useUserAccess()
  const {unitDevicesMap, loading} = usePropertyDevices(userAccess.properties)
  const unavailableDevices = useUnavailableDevices(assignedVendorId)

  const devices = useQuery<
    TGetAllDeviceWorkAssignmentsResponse,
    TGetAllDeviceWorkAssignmentsVariables
  >(GET_ACTIVE_WORK_ASSIGMENTS, {
    variables: {
      condition: {
        assignedVendorId: assignedVendorId as number,
      },
      filter: {
        deviceClassTypeId: {
          notIn: [
            DeviceClassTypes.BRIDGE,
            DeviceClassTypes.DOOR_SENSE,
            DeviceClassTypes.UNKNOWN,
          ],
        },
      },
    },
    fetchPolicy: 'network-only',
    skip: !assignedVendorId,
    onError(e) {
      showToast({
        title: 'Request Error',
        message: 'Unable to Get Device Work Assignments',
        type: 'error',
      })
    },
  })

  const assignments = devices.data?.transactionalDb.assignments.nodes

  const assignmentsByInventoryId = useMemo<Record<string, TWorkAssignment>>(() => {
    const res = arrayToObject(assignments, item => {
      return getInventoryId(item.unitId, item.deviceTypeId, item.deviceLocationTypeId)
    })

    return res
  }, [assignments])

  const getUnitBaseDevices = useCallback(
    (unitId: string) => {
      if (!unitDevicesMap[unitId]) {
        return []
      }

      const unitDevices: TDeviceItem[] = unitDevicesMap[unitId]?.map(device => {
        const inventoryId = getInventoryId(
          unitId,
          device.device.typeId,
          device.location.typeId,
        )

        const disabled = !!unavailableDevices.devicesByInventoryId[inventoryId]

        return {
          ...device,
          disabled,
          id: getDeviceItemCheckboxId(device.device.classTypeId, device.location.typeId),
          name:
            device.location.description +
            ' ' +
            deviceDescription[device.device.classTypeId],
        }
      })

      return unitDevices
    },
    [unitDevicesMap, unavailableDevices.devicesByInventoryId],
  )

  const checkDeviceFromAssignment = useCallback(
    (devices: TDeviceItem[], assignment: TWorkAssignment) => {
      if (!devices) {
        return []
      }

      const key = getDeviceItemCheckboxId(
        assignment.deviceClassTypeId,
        assignment.deviceLocationTypeId,
      )

      const inventoryId = getInventoryId(
        assignment.unitId,
        assignment.deviceTypeId,
        assignment.deviceLocationTypeId,
      )

      return devices.map((device: TDeviceItem) =>
        device.id === key
          ? {
              ...device,
              checked: !unavailableDevices.devicesByInventoryId[inventoryId],
              disabled: !!unavailableDevices.devicesByInventoryId[inventoryId],
            }
          : device,
      )
    },
    [unavailableDevices.devicesByInventoryId],
  )

  const accessPointsAssignments = useMemo(() => {
    if (loading || devices.loading || unavailableDevices.loading) {
      return {}
    }

    if (devices.data) {
      const data = devices.data.transactionalDb.assignments.nodes

      return data.reduce<TAccessPointsValue<TDevices>>((result, assignment) => {
        const {propertyId, buildingId, unitId, buildingName} = assignment

        if (!result[propertyId]) {
          result[propertyId] = {
            buildings: {},
            data: null,
          }
        }

        if (!result[propertyId]!.buildings[buildingId]) {
          result[propertyId]!.buildings[buildingId] = {
            buildingName,
            units: {},
            data: null,
          }
        }

        const buildingUnits = result[propertyId]?.buildings?.[buildingId]?.units

        if (buildingUnits && !buildingUnits?.[unitId]) {
          buildingUnits[unitId] = getUnitBaseDevices(unitId)
        }

        buildingUnits![unitId] = checkDeviceFromAssignment(
          buildingUnits![unitId]!,
          assignment,
        )

        return result
      }, {})
    }

    return {}
  }, [
    loading,
    devices.loading,
    devices.data,
    unavailableDevices.loading,
    checkDeviceFromAssignment,
    getUnitBaseDevices,
  ])

  return {
    assignmentsByInventoryId,
    accessPointsAssignments,
    loading:
      devices.loading || unavailableDevices.loading || loading || userAccess.loading,
  }
}

export default useVendorAssignments
