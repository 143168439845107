import {useEffect, useState} from 'react'
import './StaffOverview.style.scss'
import {capitalize, toCommonDateFormat} from '../../functions'
import Badge from '../../components/Badge'
import Button from '../../components/Button'
import Section from '../../components/Grid/Section'
import Breadcrumbs from '../../components/Breadcrumbs'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Panel from '../../components/Panel'
import DataGrid from '../../components/DataGrid'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../ScreenCrash/CrashScreen'
import Spinner from '../../components/Spinner/Spinner'
import {useMutation, useQuery} from '@apollo/client'
import {GET_EMPLOYEE} from '../../data/graphql/queries/people'
import {
  TGetEmployeeResponse,
  TGetEmployeeVariables,
} from '../../data/graphql/queries/people/types'
import {CrumbProps} from '../../components/Breadcrumbs/Crumb'
import AccessPoints from '../../layouts/VendorUserLayout/ServiceAccessPoints/ServiceAccessPoints'
import useScheduleAccessPoints, {
  TServiceCommonAreaAccess,
} from '../../layouts/VendorUserLayout/useScheduleAccessPoints'
import useToast from '../../hooks/useToast'
import {
  TSendAppInviteResponse,
  TSendAppInviteVariables,
} from '../../data/graphql/queries/utility/types'
import {SEND_APP_INVITE} from '../../data/graphql/queries/utility'
import PhoneNumberModal from './PhoneNumberModal'
import useGetProperty from '../../hooks/data/useGetProperty'
import useLockAccesses from '../../hooks/useLockAccesses'
import ProfileHeader from '../../components/ProfileHeader'
import {TAccessPointsValue} from '../../layouts/VendorUserLayout/useAccessPoints'
import {TAccessScheduleFields} from '../../components/AccessSchedule/AccessSchedule'

type TLockAccess = {
  lockId: string
  installedDeviceId: number
  schedule?: string
}

interface IAsignedProperty {
  name: string
  access: JSX.Element
  manager: string
  city: string
  state: string
  zip: string
  residents: number
  staff: number
}

interface IStaffInfo {
  name: string
  position: string
  isActive: boolean
  email: string
  hireDate: string
}

const StaffOverview = () => {
  const {showToast, showErrorToast} = useToast()
  const navigate = useNavigate()
  const {staffId, propertyId} = useParams()
  const location = useLocation()
  const currentPath = location.pathname
  const baseRoute = currentPath.split('/')[1] === 'reports'

  const [staffInfo, setStaffInfo] = useState<IStaffInfo | null>(null)
  const [properties, setProperties] = useState<IAsignedProperty[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [locksAccesses, setLocksAccesses] = useState<TLockAccess[]>([])
  const [commonAreaAccesses, setCommonAreaAccesses] = useState<
    TServiceCommonAreaAccess[]
  >([])

  const response = useQuery<TGetEmployeeResponse, TGetEmployeeVariables>(GET_EMPLOYEE, {
    skip: !staffId,
    variables: {
      personEmployeeId: staffId ? +staffId : 0,
      managersCondition: {
        departmentCode: 'MGR',
      },
      residentsCondition: {
        personAccessTypeId: 2,
      },
    },
  })

  const property = useGetProperty(propertyId, {
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Property Data',
        type: 'error',
      })
    },
  })

  const [sendAppInvite, inviteResponse] = useMutation<
    TSendAppInviteResponse,
    TSendAppInviteVariables
  >(SEND_APP_INVITE, {
    onCompleted: () => {
      showToast({
        title: 'Employee has been invited',
        message: `${staffInfo?.name} will receive the notification`,
        type: 'info',
      })
    },
  })

  const employee = response.data?.transactionalDb?.employee
  const yaleUserId = employee?.person?.miscInfo?.yaleLock?.userId
  const isIdentityCreated = employee?.person?.isIdentityCreated

  const accessPoints = useScheduleAccessPoints({userId: employee?.personId || ''})
  const {
    updateAppAccesses,
    updatePinAccesses,
    revokeLockAccesses,
    grantCommonAreaAccesses,
    revokeCommonAreaAccesses,
    refreshPersonAccesses,
    loading,
  } = useLockAccesses(Number(employee?.personId))

  useEffect(() => {
    if (employee?.person) {
      setStaffInfo({
        name: capitalize(`${employee.person.firstName} ${employee.person.lastName}`),
        position: employee.departmentDescription || '',
        isActive: employee.isActive,
        hireDate: employee.hireDate || '',
        email: employee.workEmail || '',
      })
    }

    if (employee?.property) {
      const property = employee?.property

      const manager = property.manager.nodes[0]?.person

      setProperties([
        {
          name: property.propertyName,
          access: (
            <Badge theme={employee.isActive ? 'info' : 'default'} size={'sm'}>
              {employee.isActive ? 'Active' : 'Inactive'}
            </Badge>
          ),
          manager: manager ? capitalize(`${manager.firstName} ${manager.lastName}`) : '—',
          city: property.address.city,
          state: property.address.state.code,
          zip: property.address.zipCode,
          residents: property.residents.totalCount,
          staff: property.staff.totalCount,
        },
      ])
    }
  }, [employee])

  const breadcrumbs: CrumbProps[] = propertyId
    ? [
        {name: 'Properties', url: '/properties/'},
        {
          name: property.data?.propertyName || '',
          url: `/properties/${propertyId}/overview`,
        },
        {name: 'Staff' || '', url: `/properties/${propertyId}/staff`},
        {
          name: staffInfo?.name || '',
          url: currentPath,
        },
        {name: 'Overview', url: ''},
      ]
    : [
        {name: 'People', url: baseRoute ? '/reports/people/' : '/people/'},
        {name: 'Staff', url: baseRoute ? '/reports/people/staff/' : '/people/staff'},
        {
          name: staffInfo?.name || '',
          url: baseRoute
            ? `/reports/people/staff/${currentPath.split('/')[4]}`
            : `/people/staff/${currentPath.split('/')[3]}`,
        },
        {name: 'Overview', url: ''},
      ]

  const onPressInvite = async () => {
    let accesses: Awaited<ReturnType<typeof accessPoints.submitForm>>

    try {
      accesses = await accessPoints.submitForm()
    } catch (e) {
      return showErrorToast(
        'Failed to parse access data',
        'Please, refresh the page and try again',
      )
    }

    const hasAccessToChange = Object.values(accesses).some(
      accessArray => !!accessArray.length,
    )

    if (!hasAccessToChange) {
      return showErrorToast('Access update', 'No access changes have been detected')
    }

    if (!employee?.person?.mobilePhone) {
      setLocksAccesses(accesses.lockAccessesRequested)
      setCommonAreaAccesses(accesses.commonAreaPropertyAccessesRequested)
      setModalOpen(true)

      return
    }

    onSubmit({
      phone: employee?.person?.mobilePhone,
      lockAccessesRequested: accesses.lockAccessesRequested,
      lockAccessesDeleted: accesses.lockAccessesDeleted,
      commonAreaAccessesDeleted: accesses.commonAreaPropertyAccessesDeleted,
      commonAreaAccessesRequested: accesses.commonAreaPropertyAccessesRequested,
    })
  }

  const handleUserInvite = async (
    phoneNumber: string,
    requestedAccesses: TLockAccess[],
    requestedCommonAreaAccesses: TServiceCommonAreaAccess[],
  ) => {
    try {
      if (!employee?.person || !phoneNumber) {
        throw new Error('Invalid data')
      }

      await sendAppInvite({
        variables: {
          input: {
            sender: {
              lastName: 'Platform',
              firstName: 'Apthub',
            },
            invitee: {
              email: employee.workEmail || '',
              firstName: employee.person.firstName,
              lastName: employee.person.lastName,
              mobilePhone: phoneNumber,
              personType: 'EMPLOYEE',
            },
            requestedThermostatAccesses: [],
            requestedLockAccesses: requestedAccesses.map(
              ({schedule, installedDeviceId}) => ({
                installedDeviceId,
                schedule,
              }),
            ),
            requestedCommonAreaLockAccesses: requestedCommonAreaAccesses.map(
              ({propertyId}) => ({
                propertyId: Number(propertyId),
              }),
            ),
          },
        },
      })
    } catch (e) {
      showErrorToast('Invite failure', 'Failed to send user invite')
    }
  }

  const onSubmit = async (values: {
    phone: string
    lockAccessesRequested?: TLockAccess[]
    lockAccessesDeleted?: TLockAccess[]
    commonAreaAccessesRequested?: TServiceCommonAreaAccess[]
    commonAreaAccessesDeleted?: string[]
  }) => {
    const requestedAccesses = values.lockAccessesRequested || locksAccesses
    const requestedCommonAreaAccesses =
      values.commonAreaAccessesRequested || commonAreaAccesses

    if (!isIdentityCreated || !yaleUserId) {
      return handleUserInvite(
        values.phone,
        requestedAccesses,
        requestedCommonAreaAccesses,
      )
    }

    await Promise.allSettled([
      revokeLockAccesses(values.lockAccessesDeleted),
      revokeCommonAreaAccesses(values.commonAreaAccessesDeleted),
    ])

    const [appAccessesResponse] = await Promise.allSettled([
      updateAppAccesses(requestedAccesses),
      grantCommonAreaAccesses(requestedCommonAreaAccesses),
    ])

    if (appAccessesResponse.status === 'fulfilled') {
      const failedLocks = appAccessesResponse.value

      await updatePinAccesses(
        requestedAccesses.filter(({lockId}) => !failedLocks.includes(lockId)),
      )
    }

    await refreshPersonAccesses()

    setModalOpen(false)
  }

  const onPressActivity = () => {
    navigate(
      encodeURI(
        `/activity-logs/locks?limit=10&page=1&orderBy=TIME_STAMP_ASC&searchTerm=${staffInfo?.name}`,
      ),
    )
  }

  const onChangeAccessPoint = (value: TAccessPointsValue<TAccessScheduleFields>) => {
    accessPoints.setFieldValue('accessPoints', value)
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      {response.loading ? (
        <Spinner />
      ) : (
        <div className={'StaffOverview'} data-testid={'StaffOverview'}>
          <PhoneNumberModal
            isLoading={inviteResponse.loading || loading}
            isOpen={modalOpen}
            onSubmit={onSubmit}
            onCloseRequest={() => setModalOpen(false)}
          />

          <Section>
            <Breadcrumbs showBack={true} crumbs={breadcrumbs} />
          </Section>

          <ProfileHeader spacing={'section-md'} wrapperId={'contact-card'}>
            <ProfileHeader.PersonCard
              name={staffInfo?.name}
              subtitle={staffInfo?.position}
              badgeTheme={staffInfo?.isActive ? 'info' : 'default'}
              badgeLabel={staffInfo?.isActive ? 'Active' : 'Inactive'}
            />

            <ProfileHeader.InfoCard
              title={'Hire Date'}
              subtitle={toCommonDateFormat(staffInfo?.hireDate)}
            />
            <ProfileHeader.InfoCard title={'Email'} subtitle={staffInfo?.email} />
            <ProfileHeader.ButtonCard
              label='Activity'
              onClick={onPressActivity}
              className='activity-container'
            />
          </ProfileHeader>

          <Section spacing={'none'} id='asigned-properties'>
            <div className='d-flex space-between align-center'>
              <h4>Assigned Properties</h4>
            </div>

            <Panel theme={'white'}>
              <DataGrid
                id='staff-assigned-properties'
                loading={false}
                columns={[
                  {key: 'name', name: 'Property Name'},
                  {key: 'access', name: 'Access'},
                  {key: 'city', name: 'City'},
                  {key: 'state', name: 'State'},
                  {key: 'zip', name: 'Zip Code'},
                  {key: 'residents', name: 'Residents'},
                  {key: 'staff', name: 'Staff'},
                ]}
                rows={properties}
              />
            </Panel>
          </Section>

          <Section id='access-points-section'>
            <AccessPoints
              noSchedule
              isVendorIdentityCreated={true}
              personId={Number(employee?.personId)}
              defaultSchedule={accessPoints.values.schedule}
              value={accessPoints.values.accessPoints}
              onChange={onChangeAccessPoint}
            />
          </Section>

          <Section id='actions'>
            <Button onClick={onPressInvite}>Update user accesses</Button>
          </Section>
        </div>
      )}
    </ErrorBoundary>
  )
}

export default StaffOverview
