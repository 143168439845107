import './Properties.style.scss'

import Column from '../../components/Grid/Column'
import Row from '../../components/Grid/Row'
import Section from '../../components/Grid/Section'
import PropertyCard from './PropertyCard'
import {useEffect, useState} from 'react'
import {TPropertyCardData} from './PropertyCard/PropertyCard'
import {useNavigate} from 'react-router-dom'
import CrashScreen from '../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import {capitalize} from '../../functions'
import usePropertiesData from '../../hooks/data/usePropertiesData'

const Properties = () => {
  const navigate = useNavigate()
  const properties = usePropertiesData()

  const [propertyRows, setPropertyRows] = useState<(TPropertyCardData & {id: string})[]>(
    [],
  )

  useEffect(() => {
    if (!properties.data) {
      setPropertyRows([])
      return
    }

    setPropertyRows(
      properties.data.map(prop => {
        const {address1, city, zipCode, stateByStateId} = prop.addressByAddressId
        const state = stateByStateId.code || ''
        const manager = prop.staff.nodes[0]?.personByPersonId
        const managerName = manager ? `${manager.lastName} ${manager.firstName}` : ''

        return {
          id: prop.propertyId.toString(),
          name: prop.propertyName,
          propertyImage:
            'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
          location: `${address1} - ${city}, ${state} ${zipCode}`,
          propertyManager: {
            img: '',
            position: 'Manager',
            name: capitalize(managerName),
          },
          residentsCount: prop.residents.totalCount.toString(),
          guestsCount: prop.guests.totalCount.toString(),
          referralsPercentage: '##',
          rentDue: '$##,###',
          occupation: '#% Full',
        }
      }),
    )
  }, [properties.data])

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Properties'} data-testid={'Properties'}>
        <Section>
          <Row>
            <Column>
              <h3 className={'color-black page-title'}>Properties</h3>
            </Column>
          </Row>
        </Section>

        <Section>
          <Row>
            {propertyRows.map((property, index) => (
              <PropertyCard
                key={index}
                data={property}
                className='property-card'
                onClick={() => {
                  navigate(`/properties/${property.id}/overview`)
                }}
              />
            ))}
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default Properties
