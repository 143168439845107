import {useQuery} from '@apollo/client'
import {GET_PROPERTIES} from '../../data/graphql/queries/properties'
import {
  TPropertiesListResponse,
  TPropertiesListVariables,
} from '../../data/graphql/queries/properties/types'
import useUserAccess from '../../hooks/useUserAccess'
import useToast from '../../hooks/useToast'
import {AccessTypes} from '../../data/graphql/queries/common/types'

const usePropertiesData = () => {
  const userAccess = useUserAccess()
  const {showErrorToast} = useToast()

  const response = useQuery<TPropertiesListResponse, TPropertiesListVariables>(
    GET_PROPERTIES,
    {
      variables: {
        staffCondition: {
          isActive: true,
          departmentCode: 'MGR',
        },
        residentsFilter: {
          personAccessTypeId: {
            equalTo: AccessTypes.R,
          },
          isActive: {
            equalTo: true,
          },
          isDeleted: {
            equalTo: false,
          },
        },
        guestsFilter: {
          isActive: {
            equalTo: true,
          },
          isDeleted: {
            equalTo: false,
          },
          personAccessTypeId: {
            in: [AccessTypes.G, AccessTypes.P],
          },

          or: [
            {
              miscInfo: {
                contains: {
                  lockAccess: {
                    yaleUserType: 'UNVERIFIED',
                  },
                },
              },
            },
            {
              miscInfo: {
                contains: {
                  lockAccess: {
                    yaleUserType: 'LIMITED',
                  },
                },
              },
            },
          ],
        },
        filter: {
          propertyId: {
            in: userAccess.properties,
          },
        },
      },
      onError() {
        showErrorToast('Request Error', 'Failed to fetch proeprties data')
      },
    },
  )

  const data = response.data?.transactionalDb.allProperties.nodes

  return {
    data,
    response,
    loading: response.loading,
  }
}

export default usePropertiesData
