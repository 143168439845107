import './VendorOverview.style.scss'
import {useQuery} from '@apollo/client'
import {useLocation, useParams} from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumbs'
import Section from '../../components/Grid/Section'
import VendorUsersPanel from './VendorUsers/VendorUsersPanel'
import {TVendorResponse, TVendorVariables} from '../../data/graphql/queries/people/types'
import {GET_VENDOR} from '../../data/graphql/queries/people'
import Row from '../../components/Grid/Row'
import Column from '../../components/Grid/Column'
import Tabbar from '../../components/Tabbar/Tabbar'
import {useState} from 'react'
import CrashScreen from '../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import useToast from '../../hooks/useToast'
import Spinner from '../../components/Spinner/Spinner'
import {formatPhoneNumber} from '../../functions'
import ProfileHeader from '../../components/ProfileHeader'
import PendingVendorInvitesPanel from './PendingInvites/PendingVendorInvitesPanel'

const VendorOverview = () => {
  const {showToast} = useToast()
  const location = useLocation()
  const {vendorId} = useParams()
  const baseRoute = location.pathname.includes('reports')
  const id = Number(vendorId)

  const [panel, setPanel] = useState('staff')

  const vendorResponse = useQuery<TVendorResponse, TVendorVariables>(GET_VENDOR, {
    variables: {
      vendorId: id,
    },
    skip: !id,
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Vendor Data',
        type: 'error',
      })
    },
  })

  const vendor = vendorResponse.data?.transactionalDb.vendorByVendorId

  const breadcrumbsProps = {
    showBack: true,
    crumbs: [
      {name: 'People', url: baseRoute ? '/reports/' : '/people/'},
      {name: 'Vendors', url: baseRoute ? '/reports/people/vendors' : '/people/vendors'},
      {
        name: vendor?.vendorName || '',
        url: baseRoute
          ? `/reports/people/vendors/${vendor?.vendorId || ''} `
          : `/people/vendors/${vendor?.vendorId || ''}`,
      },
    ],
  }

  const renderPanel = () => {
    if (panel === 'staff') {
      return <VendorUsersPanel />
    } else if (panel === 'pendingInvites') {
      return <PendingVendorInvitesPanel />
    }

    return null
  }

  if (vendorResponse.loading) {
    return <Spinner />
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'VendorOverview'} data-testid={'VendorOverview'}>
        <Section>
          <Breadcrumbs {...breadcrumbsProps} />
        </Section>

        <ProfileHeader spacing={'section-md'} wrapperId={'contact-card'}>
          <ProfileHeader.PersonCard
            hideSubtitle
            name={vendor?.vendorName}
            badgeTheme={vendor?.isActive ? 'info' : 'danger'}
            badgeLabel={vendor?.isActive ? 'Active' : 'Inactive'}
          />

          <ProfileHeader.InfoCard
            title={'Call'}
            subtitle={formatPhoneNumber(vendor?.contactPerson?.workPhone)}
          />
          <ProfileHeader.InfoCard
            title={'Email'}
            subtitle={vendor?.contactPerson?.email || ''}
          />
        </ProfileHeader>
        <Section spacing='none'>
          <Row>
            <Column xs={'12'}>
              <Tabbar
                tabs={[
                  {label: 'Staff', key: 'staff'},
                  {label: 'Pending invites', key: 'pendingInvites'},
                ]}
                selected={panel}
                onTabChange={setPanel}
              />
            </Column>
          </Row>
        </Section>

        {renderPanel()}
      </div>
    </ErrorBoundary>
  )
}

export default VendorOverview
