// forms/ForgotPassword/ForgotPassword.tsx

import {FC, FormEvent, useState, useContext} from 'react'
import {useLazyQuery} from '@apollo/client'
import {getAuth, sendPasswordResetEmail, signInAnonymously} from 'firebase/auth'

import './ForgotPassword.style.scss'
import InputText from '../../components/InputText/InputText'
import Button from '../../components/Button'
import {validateEmail, randomString, formatIdentityError} from '../../functions'
import ButtonRow from '../../components/ButtonRow'
import {AuthContext} from '../../contexts/AuthContext'
import {GET_INFO_FOR_LOGIN} from '../../data/graphql/queries/auth'
import {
  TInfoForLoginReponse,
  TInfoForLoginVariables,
} from '../../data/graphql/queries/auth/types'

export interface ForgotPasswordProps {
  id?: string
  value?: any
  valueChange?: any
}

const defaultProps: ForgotPasswordProps = {
  id: randomString(),
}

const ForgotPassword: FC<ForgotPasswordProps> = (props: ForgotPasswordProps) => {
  props = {...defaultProps, ...props}
  const auth = getAuth()

  const {setUser} = useContext(AuthContext)
  const [email, setEmail] = useState<string | number>('')
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<{message: string; success: boolean}>({
    message: '',
    success: false,
  })

  const [fetchTenantId, {loading, error, data}] = useLazyQuery<
    TInfoForLoginReponse,
    TInfoForLoginVariables
  >(GET_INFO_FOR_LOGIN, {
    notifyOnNetworkStatusChange: true,
    onCompleted: response =>
      sendPasswordReset(response?.utility?.getInfoForLogin?.idpTenantId),
    onError: error => {
      setErrorMessage({message: 'Error submitting email!', success: false})
      setPageLoading(false)
    },
  })

  const isDisabled = (): boolean => {
    return !validateEmail(email as string)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrorMessage({message: '', success: false})
    setPageLoading(true)

    try {
      auth.tenantId = process.env.REACT_APP_GOOGLE_DEFAULT_IDP_TENANT || null

      if (
        auth.currentUser?.isAnonymous &&
        auth.currentUser?.tenantId !== process.env.REACT_APP_GOOGLE_DEFAULT_IDP_TENANT
      ) {
        await auth.signOut()
      }

      await signInAnonymously(auth)
      fetchTenantId({
        variables: {input: {email: email as string}},
      })
    } catch (e: any) {
      const errorMessageCode = e.code
      setErrorMessage({message: formatIdentityError(errorMessageCode), success: false})
      setPageLoading(false)
    }
  }

  const sendPasswordReset = (tenantId: string | null) => {
    auth.tenantId = tenantId

    sendPasswordResetEmail(auth, email as string)
      .then(() => {
        setErrorMessage({message: 'Please check your email!', success: true})
      })
      .catch(errorMessage => {
        const errorMessageCode = errorMessage.code
        setErrorMessage({message: formatIdentityError(errorMessageCode), success: false})
      })

    setPageLoading(false)
  }

  return (
    <>
      {pageLoading && !errorMessage.message ? (
        <div>Loading...</div>
      ) : (
        <form
          id={props.id}
          className={'forgot-password-form'}
          onSubmit={e => handleSubmit(e)}
        >
          <InputText
            id={'forgot-password-email'}
            type={'email'}
            label={'Email'}
            placeholder={'Email Address'}
            value={email}
            onValueChange={setEmail}
          />
          {errorMessage.message && (
            <div
              className={`color-${errorMessage.success ? 'green' : 'red'} error-message`}
            >
              {errorMessage.message}
            </div>
          )}
          <ButtonRow>
            <Button
              theme={'default'}
              width={'block'}
              type={'submit'}
              disabled={isDisabled()}
            >
              Reset Password
            </Button>
            <Button type={'button'} theme={'link'} width={'block'} to={'/'}>
              Login
            </Button>
          </ButtonRow>
        </form>
      )}
    </>
  )
}

export default ForgotPassword
