import {gql} from '@apollo/client'

export const GRANT_FULL_ACCESS_TO_LOCKS = gql`
  mutation GrantFullAccessToLocks($input: GrantFullAccessToLocksInput!) {
    lock {
      grantFullAccessToLocks(input: $input) {
        ... on CommonError {
          code
          message
          status
          source
        }
        ... on GrantFullAccessToLocksResult {
          failed {
            userType
            yaleDeviceId
            reason
          }
          granted {
            yaleDeviceId
            userType
          }
        }
      }
    }
  }
`

export const GRANT_LIMITED_ACCESS_TO_LOCKS = gql`
  mutation GrantLimitedAccessToLocks($input: GrantLimitedAccessToLocksInput!) {
    lock {
      grantLimitedAccessToLocks(input: $input) {
        ... on CommonError {
          code
          message
          status
          source
        }
        ... on GrantLimitedAccessToLocksResult {
          created {
            yaleDeviceId
            accessType
            ruleId
          }
          failed {
            yaleDeviceId
            accessType
            reason
          }
        }
      } 
    }
  }
`

export const REVOKE_ACCESSES_TO_LOCKS = gql`
  mutation RevokeAccessToLocks($input: RevokeAccessToLocksInput!) {
    lock {
      revokeAccessToLocks(input: $input) {
        ... on RevokeLockAccessResult {
          failed {
            userType
            reason
            yaleLockId
          }
          revoked {
            yaleLockId
            userType
          }
        }
        ... on CommonError {
          message
          code
          source
          status
        }
      }
    }
  }
`

export const REVOKE_ALL_LOCK_ACCESSES = gql`
  mutation RevokeAllLockAccesses($input: RevokeAllLockAccessInput!) {
    lock {
      revokeAllLockAccess(input: $input) {
        ... on RevokeLockAccessResult {
          failed {
            reason
            userType
            yaleLockId
          }
          revoked {
            userType
            yaleLockId
          }
        }
        ... on CommonError {
          code
          message
          source
          status
        }
      }
    }
  }
`

export const SET_LOCK_PINS = gql`
  mutation Lock($input: SetLockPinsInput!) {
    lock {
      setLockPins(input: $input) {
        ... on CommonError {
          code
          message
          status
          source
        }
        ... on SetLockPinsResult {
          submitted {
            deviceId
            transactionId
            accessType
          }
          failed {
            deviceId
            reason
          }
          pin
        }
      }
    }
  }
`

export const CANCEL_ASYNC_TRANSACTION = gql`
  mutation Mutation(
    $input: CancelUnsuccessfulAsyncTransactionInput!
  ) {
    utility {
      cancelUnsuccessfulAsyncTransaction(input: $input) {
        ... on CancelUnsuccessfulAsyncTransactionSuccess {
          softDeletedAsyncTransactionId
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`

export const REVOKE_COMMON_AREA_ACCESSES = gql`
  mutation RevokeAccessFromCommonAreaLocks($input: RevokeAccessFromCommonAreaLocksInput!) {
    lock {
      revokeAccessFromCommonAreaLocks(input: $input) {
        ... on RevokeAccessFromCommonAreaLocksSuccess {
          success
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`

export const GRANT_COMMON_AREA_ACCESSES = gql`
  mutation GrantAccessToCommonAreaLocks($input: GrantAccessToCommonAreaLocksInput!) {
    lock {
      grantAccessToCommonAreaLocks(input: $input) {
        ... on GrantAccessToCommonAreaLocksSuccess {
          userId
          accessCode
        }
        ... on RetriableError {
          message
          source
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`
