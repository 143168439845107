import {TPerson} from '../entities'

export type TFilterStringMatchers = {
  distinctFrom: string
  distinctFromInsensitive: string
  endsWith: string
  endsWithInsensitive: string
  equalTo: string
  equalToInsensitive: string
  greaterThan: string
  greaterThanInsensitive: string
  greaterThanOrEqualTo: string
  greaterThanOrEqualToInsensitive: string
  in: null | string[]
  inInsensitive: string[]
  includes: string
  includesInsensitive: string
  isNull: boolean
  lessThan: string
  lessThanInsensitive: string
  lessThanOrEqualTo: string
  lessThanOrEqualToInsensitive: string
  like: string
  likeInsensitive: string
  notDistinctFrom: string
  notDistinctFromInsensitive: string
  notEndsWith: string
  notEndsWithInsensitive: string
  notEqualTo: string
  notEqualToInsensitive: string
  notIn: string[]
  notInInsensitive: string[]
  notIncludes: string
  notIncludesInsensitive: string
  notLike: string
  notLikeInsensitive: string
  notStartsWith: string
  notStartsWithInsensitive: string
  startsWith: string
  startsWithInsensitive: string
}

export type TFilterNumberMatchers = {
  distinctFrom: number
  equalTo: number
  greaterThan: number
  greaterThanOrEqualTo: number
  in: null | number[]
  isNull: boolean
  lessThan: number
  lessThanOrEqualTo: number
  notDistinctFrom: number
  notEqualTo: number
  notIn: number[]
}

export type TFilterBooleanMatchers = {
  notEqualTo: boolean
  equalTo: boolean
}

export type TFilterJSONMatchers = {
  contains: object
  containsAllKeys: string[]
  containsAnyKeys: string[]
  containsKey: string
  equalTo: object
  isNull: boolean
}

export type TFilterMatchers =
  | TFilterNumberMatchers
  | TFilterStringMatchers
  | TFilterBooleanMatchers
  | TFilterJSONMatchers

export type TQueryFilter<Fields = any> = Partial<{
  [key in keyof Fields]: Partial<TFilterMatchers>
}> & {
  or?: TQueryFilter<Fields>[]
  and?: TQueryFilter<Fields>[]
  not?: TQueryFilter<Fields>
}

export enum AccessTypes {
  NC = 0, // Unknown
  O = 1, // Owner
  R = 2, // Resident
  G = 3, // Guest
  P = 4, // Pin Code Entry
}

export enum TAccessTypeCode {
  NC = 'NC', // Unknown
  O = 'O', // Owner
  R = 'R', // Resident
  G = 'G', // Guest
  P = 'P', // Pin Code Entry
  PA = 'PA', // Property Account
  BA = 'BA', // Building Account
}

export enum TAccessTypeId {
  NC = 0, // Unknown
  O = 1, // Owner
  R = 2, // Resident
  G = 3, // Guest
  P = 4, // Pin Code Entry
  PA = 5, // Property Account
  BA = 6, // Building Account
}

export enum SecretTypeId {
  'NC' = 0,
  'AT' = 1,
  'PW' = 2,
  'MPC' = 3,
  'RT' = 4,
  'BAEP' = 5,
}

export enum ManufacturerTypeId {
  'NC' = 0,
  'Y' = 1,
  'N' = 2,
  'B' = 3,
  'H' = 5,
}

export enum TPersonTypeCode {
  NC = 'NC', // Unknown
  T = 'T', // Tenant
  R = 'R', // Resident
  V = 'V', // Vendor
  E = 'E', // Employee
  C = 'C', // Contractor
  G = 'G', // Guest
  B = 'B', // Building
  SGT = 'SGT', // Self-Guided Tour
  DA = 'DA', // Developer Admin
}

export enum TResidentTypeCode {
  NC = 'NC', // Unknown
  G = 'G', // Guarantor
  HOH = 'HOH', // Head of Household
  CHOH = 'CHOH', // Co-Head of Household
  AR = 'AR', // Adult Resident
  CR = 'CR', // Child Resident
}

export enum TLeaseTypeCode {
  'NC' = 'NC', // Unknown
  'FL' = 'FL', // First Lease
  'MO' = 'MO', // Moved Out
  'NG' = 'NG', // Notice Given
  'R' = 'R', // Renewal
}

export type TServiceTicketCategoryType = {
  id: string
  description: string
}

export type TServiceTicketIssueLocationType = {
  id: string
  description: string
}

export type TLeaseType = {
  id: string
  description: string
}

export type TAllTypesResponse = {
  transactionalDb: {
    allPersonAccessTypes: {
      nodes: TAccessType[]
    }
    allPersonTypes: {
      nodes: TPersonType[]
    }
    allResidentTypes: {
      nodes: TResidentType[]
    }
    allServiceTicketCategoryTypes: {
      nodes: TServiceTicketCategoryType[]
    }
    allServiceTicketIssueLocationTypes: {
      nodes: TServiceTicketIssueLocationType[]
    }
    allLeaseTypes: {
      nodes: TLeaseType[]
    }
  }
}

export type TPersonType = {
  code: TPersonTypeCode
  description: string
  personTypeId: number
}

export type TAccessType = {
  code: TAccessTypeCode
  description: string
  personAccessTypeId: number
}

export type TResidentType = {
  code: TResidentTypeCode
  description: string
  residentTypeId: number
}

export type TBuilding = {
  nodeId: string
  buildingId: string
  buildingName: string
}

export type TAllPersonHouseAccountsVariables = {
  condition: {
    isActive: boolean
    isDeleted: boolean
  }
}

export type TAllPersonHouseAccountsResponse = {
  transactionalDb: {
    accounts: {
      nodes: {
        buildingId: string
        person: {
          id: TPerson['personId']
          email: TPerson['email']
          mobilePhone: TPerson['mobilePhone']
        }
      }[]
    }
  }
}

export type TAllBuidlingsVariables = {
  first?: number
  offset?: number
  condition: {
    propertyId?: number
    isActive: boolean
    isDeleted: boolean
  }
  filter?: TQueryFilter<{
    propertyId?: number
  }>
}

export type TAllBuildingsResponse = {
  transactionalDb: {
    allBuildings: {
      nodes: TBuilding[]
    }
  }
}

export type TUnit = {
  nodeId: string
  unitId: number
  unitName: string
}

export type TAllBuildingsWithUnitsVariables = {
  first?: number
  offset?: number
  condition?: {
    propertyId?: number
    buildingId?: number
  }
  filter?: TQueryFilter<{
    propertyId?: number
  }>
}

export type TBuildingWithUnits = {
  buildingId: string
  buildingName: string
  unitsByBuildingId: {
    nodes: {
      unitId: string
      floorPlanId: string
      unitNumber: string
      unitType: {
        description: string
      }
    }[]
  }
}

export type TAllBuildingsWithUnitsResponse = {
  transactionalDb: {
    allBuildings: {
      nodes: TBuildingWithUnits[]
    }
  }
}

export type TAllUnitsVariables = {
  first?: number
  offset?: number
  orderBy?: string[]
  condition?: {
    buildingId?: number
    propertyId?: number
    isActive: boolean
    isDeleted: boolean
  }
  filter?: {[key: string]: any}
  leasesCondition: {
    isActive: boolean
    isDeleted: boolean
  }
}

export type TAllUnitsResponse = {
  transactionalDb: {
    allUnits: {
      nodes: {
        unitId: string
        unitNumber: string
        unitTypeByUnitTypeId: {
          description: string
        }
      }[]
    }
  }
}

export type TAllUnitsOfProperyResponse = {
  transactionalDb: {
    allUnits: {
      totalCount: number
      nodes: TUnitOfProperty[]
    }
  }
}
export interface TUnitOfProperty {
  unitId: string
  buildingId: string
  unitNumber: string
  unitTypeByUnitTypeId: UnitTypeByUnitTypeId
  buildingByBuildingId: BuildingByBuildingId
  leasesByUnitId: {
    nodes: [
      {
        leaseEndDate: string
        leaseBeginDate: string
        rentAmount: string
        leaseholder: {
          firstName: string
          lastName: string
        }
      },
    ]
  }
  floorPlanByFloorPlanId?: {name: string}
}

export interface UnitTypeByUnitTypeId {
  description: string
}

export interface BuildingByBuildingId {
  buildingName: string
}

export type TState = {
  stateId: string
  code: string
  description: number
}

export type TAllStatesResponse = {
  transactionalDb: {
    allStates: {
      nodes: TState[]
    }
  }
}

export enum DeviceTypes {
  NEST_E = 1,
  YALE_622 = 2,
  YALE_BRIDGE = 3,
  YALE_DS = 5,
  BRIVO = 6,
  YALE_ASSURE_2 = 8,
  HONEYWELL_T6 = 9,
}

export enum DeviceClassTypes {
  UNKNOWN = 0,
  LOCK = 1,
  BRIDGE = 2,
  THERMOSTAT = 3,
  DOOR_SENSE = 4,
}

export enum LeaseTypes {
  NC = 0,
  FL = 1,
  MO = 2,
  NG = 3,
  R = 4,
}

export enum DeviceLocationTypes {
  FRONT_DOOR = 2,
}

export enum UnitTypes {
  NC = 0,
  A = 1,
  S = 2,
  M = 3,
}
