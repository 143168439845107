import {useCallback, useEffect, useMemo} from 'react'

import usePortalPersonList from './usePortalPersonList'
import {
  DeviceTypes,
  TAccessTypeCode,
  TPersonTypeCode,
  TResidentTypeCode,
} from '../../data/graphql/queries/common/types'
import {
  getGreaterThanOrEqualFilter,
  getLessThanOrEqualFilter,
  isUnitNumber,
  prepareOrder,
} from '../../functions/filters'
import {QueryOptions} from '../../models'
import {TResidentFilterFields} from '../../views/People/Residents/ResidentsFilter'
import {useLazyQuery} from '@apollo/client'
import {
  GET_PORTAL_PERSON_LIST,
  GET_UNITS_INVITES_AND_DEVICES,
} from '../../data/graphql/queries/people'
import {
  TGetInvitesResponse,
  TGetInvitesVariables,
  TPortalPerson,
} from '../../data/graphql/queries/people/types'
import {client} from '../../data/graphql'
import {format} from 'date-fns'

const usePendingInvitesData = (
  searchTerm: string,
  options: Required<QueryOptions<TResidentFilterFields>>,
) => {
  const variables = useMemo(() => {
    const {unitFilters, moveInStart, moveInEnd, moveOutStart, moveOutEnd} =
      options.filters

    const filter: {[key: string]: any} = {
      personType: {
        equalTo: TPersonTypeCode.R,
      },
      personAccessCode: {
        equalTo: TAccessTypeCode.NC,
      },
      residentType: {
        notEqualTo: TResidentTypeCode.G,
      },
      buildingId: {
        notEqualTo: Number(process.env.REACT_APP_DEVS_BUILDING_ID),
      },
      or: unitFilters || [],
      and: [
        {
          or: [
            {
              isLeaseActive: {
                equalTo: true,
              },
            },
            {
              isLeaseActive: {
                isNull: true,
              },
            },
          ],
        },
        {
          or: [
            {
              isLeaseExpired: {
                equalTo: false,
              },
            },
            {
              isLeaseExpired: {
                isNull: true,
              },
            },
          ],
        },
        {
          or: [
            {
              leaseMoveOutDate: getGreaterThanOrEqualFilter(
                format(new Date(), 'yyyy-LL-dd'),
              ),
            },
            {
              leaseMoveOutDate: {
                isNull: true,
              },
            },
          ],
        },
        {
          or: [
            {
              leaseType: {
                notEqualTo: 'MO',
              },
            },
            {
              leaseType: {
                isNull: true,
              },
            },
          ],
        },
      ],
    }

    if (moveInStart) {
      filter.and.push({
        lockScheduleStartDt: getGreaterThanOrEqualFilter(moveInStart),
      })
    }

    if (moveInEnd) {
      filter.and.push({
        lockScheduleStartDt: getLessThanOrEqualFilter(moveInEnd),
      })
    }

    if (moveOutStart) {
      filter.and.push({
        lockScheduleEndDt: getGreaterThanOrEqualFilter(moveOutStart),
      })
    }

    if (moveOutEnd) {
      filter.and.push({
        lockScheduleEndDt: getLessThanOrEqualFilter(moveOutEnd),
      })
    }

    if (searchTerm) {
      filter[isUnitNumber(searchTerm) ? 'unitNumber' : 'personName'] = {
        includesInsensitive: searchTerm,
      }
    }

    const orderBy = prepareOrder(options?.orderBy)

    return {
      first: options.limit,
      offset: options.limit * (options.page - 1),
      filter,
      condition: {
        isLeaseActive: true,
        isPersonActive: true,
        isPersonUnitActive: true,
      },
      ...(orderBy.length ? {orderBy} : {}),
    }
  }, [options, searchTerm])

  const [residents, response] = usePortalPersonList(variables)

  const [getInvitesAndDevices, invitesAndDevicesResponse] = useLazyQuery<
    TGetInvitesResponse,
    TGetInvitesVariables
  >(GET_UNITS_INVITES_AND_DEVICES)

  const invites = invitesAndDevicesResponse?.data?.transactionalDb?.allAppInvites?.nodes
  const devices =
    invitesAndDevicesResponse?.data?.transactionalDb?.allInstalledDevices?.nodes

  const prepareVariablesForInviteQuery = useCallback(
    (residents: TPortalPerson[]): TGetInvitesVariables => ({
      invitesFilter: {
        expirationDt: {
          greaterThan: new Date().toISOString(),
        },
        email: {
          in: residents.map(({email}) => email),
        },
        miscInfo: {
          contains: {
            personTypeCode: TPersonTypeCode.R,
          },
        },
        isDeleted: {
          equalTo: false,
        },
      },
      devicesCondition: {
        isDeleted: false,
        isActive: true,
      },
      devicesFilter: {
        deviceTypeId: {
          in: [DeviceTypes.YALE_622, DeviceTypes.YALE_ASSURE_2, DeviceTypes.HONEYWELL_T6],
        },
        unitId: {
          in: residents.map(({unitId}) => unitId),
        },
      },
    }),
    [],
  )

  useEffect(() => {
    if (residents.length) {
      getInvitesAndDevices({
        variables: prepareVariablesForInviteQuery(residents),
      })
    }
  }, [residents, prepareVariablesForInviteQuery, getInvitesAndDevices])

  const invitedEmails = useMemo(() => {
    if (!invites) {
      return []
    }

    return invites.map(({email}) => email)
  }, [invites])

  const installedDevices = useMemo(() => {
    if (!devices) {
      return {}
    }

    return devices.reduce<{[key: string]: Array<(typeof devices)[number]>}>(
      (result, device) => {
        const unitId = device.unitId

        if (!result[unitId]) {
          result[unitId] = []
        }

        result[unitId].push(device)

        return result
      },
      {},
    )
  }, [devices])

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {
      ...variables,
    }

    if ('first' in variables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    const allAccesses = await client.query({
      query: GET_PORTAL_PERSON_LIST,
      variables: {
        ...copiedVariables,
        filter: {
          ...copiedVariables?.filter,
          and: [
            ...(copiedVariables?.filter?.and || []),
            // TODO: propertyId for imported residents is 0, should be fixed on BE
            // {
            //   propertyId: {
            //     in: userAccess.properties,
            //   },
            // },
          ],
        },
      },
    })

    const residents = allAccesses.data?.transactionalDb.allPortalPersonViews.nodes || []

    let inviteQuery
    if (residents.length) {
      inviteQuery = await client.query({
        query: GET_UNITS_INVITES_AND_DEVICES,
        variables: prepareVariablesForInviteQuery(residents),
      })
    }

    const invites = inviteQuery?.data?.transactionalDb?.allAppInvites?.nodes || []
    const invitedEmails = invites.map(({email}) => email)
    return {accessesList: residents, invitedEmails}
  }, [variables, prepareVariablesForInviteQuery])

  return {
    residents,
    response,
    variables,
    invitedEmails,
    installedDevices,
    invitesAndDevicesResponse,
    queryForDownloadTable,
  }
}

export default usePendingInvitesData
