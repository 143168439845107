import './Dashboard.style.scss'
import Sidebar from '../../components/Sidebar'
import DashboardMenu from '../../components/Sidebar/SidebarMenuDashboard'
import {Outlet} from 'react-router-dom'
import Icon from '../../components/Icon/Icon'
import {useCallback, useState, useContext} from 'react'
import {AuthContext} from '../../contexts/AuthContext'
import {useQuery} from '@apollo/client'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'
import useToast from '../../hooks/useToast'
import useLockSubscription from '../../hooks/subscriptions/lock/LockChannelSubscriptionProvider'
import {GET_PERSON_BY_ID} from '../../data/graphql/queries/people'
import {
  TGetPersonByIdResponse,
  TGetPersonByIdVariables,
} from '../../data/graphql/queries/people/types'
import useUserPersonId from '../../hooks/useUserPersonId'

const DashboardLayout = () => {
  useLockSubscription()
  const personId = useUserPersonId()
  const {user} = useContext(AuthContext)
  const {setUser} = useContext(AuthContext)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const {showToast} = useToast()

  const handleClick = () => {
    setIsOpenMenu(prev => !prev)
  }

  const hideSidebar = useCallback(() => {
    setIsOpenMenu(false)
  }, [])

  useQuery<TGetPersonByIdResponse, TGetPersonByIdVariables>(GET_PERSON_BY_ID, {
    variables: {
      personId: Number(personId),
    },
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    onCompleted: response => {
      setUser({...user, ...response.transactionalDb.person})
    },
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Person Data',
        type: 'error',
      })
    },
  })

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'dashboard-layout'} data-testid={'DashboardLayout'}>
        <Sidebar menu={<DashboardMenu />} open={isOpenMenu} hideSidebar={hideSidebar} />

        <div className={'app-body'}>
          <div className='menu-btn' onClick={handleClick}>
            <Icon icon={!isOpenMenu ? 'menu' : 'close'} size='md' theme='info' />
          </div>
          <Outlet />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default DashboardLayout
