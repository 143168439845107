import {createContext, FC, useEffect, useState, useMemo} from 'react'

export const AuthContext = createContext<{
  user: Record<string, any>
  setUser: React.Dispatch<Record<string, any>>
}>({
  user: {},
  setUser: () => {
    //
  },
})

interface IProps {
  children: React.ReactNode
}

const getInitialState = () => {
  const team = localStorage.getItem('user')
  return team ? JSON.parse(team) : {}
}

const AuthContextProvider: FC<IProps> = props => {
  const [user, setUser] = useState<Record<string, unknown>>(getInitialState)

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user))

    if (!user?.email) {
      localStorage.setItem('devMode', 'false')
    }
  }, [user])

  const contextValue = useMemo(() => {
    return {user, setUser}
  }, [user, setUser])

  return (
    <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
  )
}

export default AuthContextProvider
