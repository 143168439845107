import {useFormik} from 'formik'
import {useContext, useEffect} from 'react'

import {useQuery} from '@apollo/client'
import * as Yup from 'yup'
import {AuthContext} from '../../contexts/AuthContext'
import {GET_PERSON_ACCESSES_BY_ID, GET_VENDOR} from '../../data/graphql/queries/people'
import {
  TPersonAccessesByIdResponse,
  TPersonAccessesByIdVariables,
  TVendorResponse,
  TVendorVariables,
} from '../../data/graphql/queries/people/types'
import useToast from '../../hooks/useToast'
import {isValidPhoneNumber} from '../../functions'

type TFields = {
  vendorName: string
  userType: 'service' | 'installer'
  firstName: string
  lastName: string
  email: string
  phone: string
}

type TUserSubmitData = {
  sender: {
    lastName: string
    firstName: string
  }
  invitee: {
    mobilePhone: string
    lastName: string
    firstName: string
    email: string
    vendorId: number
  }
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required().label('First Name'),
  lastName: Yup.string().required().label('Last Name'),
  phone: Yup.string()
    .required()
    .label('Phone Number')
    .test('isValidNumber', 'Phone number is not valid', isValidPhoneNumber),
  email: Yup.string().label('Email').email('Invalid email format').required(),
})

const useVendorUserOverview = (
  data: {vendorId: number; userId?: number},
  onSubmit: (data: TUserSubmitData) => void,
) => {
  const {showToast} = useToast()
  const {user} = useContext(AuthContext)

  const vendorResponse = useQuery<TVendorResponse, TVendorVariables>(GET_VENDOR, {
    variables: {
      vendorId: data.vendorId,
    },
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Vendor Data',
        type: 'error',
      })
    },
  })

  const personResponse = useQuery<
    TPersonAccessesByIdResponse,
    TPersonAccessesByIdVariables
  >(GET_PERSON_ACCESSES_BY_ID, {
    skip: !data.userId,
    variables: {
      personId: data.userId || 0,
      condition: {
        isActive: true,
        isDeleted: false,
      },
    },
    onError() {
      showToast({
        title: 'Request Error',
        message: 'Unable to Retrieve Person Accesses Data',
        type: 'error',
      })
    },
  })

  const person = personResponse.data?.transactionalDb.personByPersonId

  const {values, errors, touched, setFieldValue, handleChange, handleBlur, submitForm} =
    useFormik<TFields>({
      validationSchema,
      initialValues: {
        vendorName: '',
        userType: 'installer',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      onSubmit: (values, helpers) => {
        const errors = helpers.validateForm(values)

        if (!Object.keys(errors).length) {
          const sender = {
            lastName: user.lastName,
            firstName: user.firstName,
          }
          const invitee = {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            mobilePhone: values.phone,
            vendorId: data.vendorId,
          }

          onSubmit({
            sender,
            invitee,
          })
        }
      },
    })

  useEffect(() => {
    const vendorName = vendorResponse.data?.transactionalDb.vendorByVendorId?.vendorName

    setFieldValue('vendorName', vendorName || '')
  }, [setFieldValue, vendorResponse.data?.transactionalDb.vendorByVendorId?.vendorName])

  useEffect(() => {
    if (person) {
      setFieldValue('firstName', person.firstName)
      setFieldValue('lastName', person.lastName)
      setFieldValue('email', person.email || '')
      setFieldValue('phone', person.mobilePhone)
    }
  }, [person, setFieldValue])

  return {
    values,
    errors,
    touched,
    handleBlur,
    submitForm,
    handleChange,
    setFieldValue,
  }
}

export default useVendorUserOverview
