import './Thermostats.style.scss'

import {useCallback, useContext, useEffect, useState} from 'react'
import Column from '../../../components/Grid/Column'
import Panel from '../../../components/Panel'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import DataGrid from '../../../components/DataGrid'
import Icon from '../../../components/Icon'
import Paginator from '../../../components/Paginator'
import Search from '../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import {capitalize, celsiusToFahrenheit, formatDateTime} from '../../../functions'
import {TActivityFilterFields} from '../../GuestsOverview/ActivityFilters/ActivityFilters'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useThermostats from '../../../hooks/data/useThermostats'
import ThermostatsFilter from './ThermostatsFilter'
import useTableSort from '../../../hooks/useTableSort'

type TDeviceRecord = {
  name: React.ReactNode
  temperature: React.ReactNode
  location: string
  unit: string
  updatedAt: string
  mode: React.ReactNode
  heat: string
  cool: string
  humidity: string
}

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Number Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Number Desc'},
  {sortKey: 'heat:asc', value: 'HEAT_CELSIUS_SETTING_ASC', label: 'Heat Setting Asc'},
  {sortKey: 'heat:desc', value: 'HEAT_CELSIUS_SETTING_DESC', label: 'Heat Setting Desc'},
  {sortKey: 'cool:asc', value: 'COOL_CELSIUS_SETTING_ASC', label: 'Cool Setting Asc'},
  {sortKey: 'cool:desc', value: 'COOL_CELSIUS_SETTING_DESC', label: 'Cool Setting Desc'},
  {sortKey: 'mode:asc', value: 'MODE_ASC', label: 'Mode Asc'},
  {sortKey: 'mode:desc', value: 'MODE_DESC', label: 'Mode Desc'},
  {
    sortKey: 'temperature:asc',
    value: 'AMBIENT_TEMPERATURE_CELSIUS_ASC',
    label: 'Temperature Asc',
  },
  {
    sortKey: 'temperature:desc',
    value: 'AMBIENT_TEMPERATURE_CELSIUS_DESC',
    label: 'Temperature Desc',
  },
  {sortKey: 'humidity:asc', value: 'AMBIENT_HUMIDITY_PERCENT_ASC', label: 'Humidity Asc'},
  {
    sortKey: 'humidity:desc',
    value: 'AMBIENT_HUMIDITY_PERCENT_DESC',
    label: 'Humidity Desc',
  },
  {sortKey: 'updatedAt:asc', value: 'LAST_UPDATED_DT_ASC', label: 'Updated Date Asc'},
  {sortKey: 'updatedAt:desc', value: 'LAST_UPDATED_DT_DESC', label: 'Updated Date Desc'},
  {sortKey: 'location:asc', value: 'LOCATION_ASC', label: 'Location Asc'},
  {sortKey: 'location:asc', value: 'LOCATION_DESC', label: 'Location Desc'},
  {sortKey: 'name:asc', value: 'DEVICE_TYPE_ASC', label: 'Name Asc'},
  {sortKey: 'name:desc', value: 'DEVICE_TYPE_DESC', label: 'Name Desc'},
]

const Thermostats = () => {
  const {
    queryOptions,
    debouncedSearchTerm,
    upsertQueryOptions,
    onChangeNumberOfItems,
    setQueryOptions,
  } = useQueryOptions({
    page: 1,
    orderBy: ['LAST_UPDATED_DT_DESC'],
    searchTerm: '',
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const [tableData, settableData] = useState<TDeviceRecord[]>([])
  const {setQuery} = useContext(ExportTableContext)
  const {devices, response, queryForDownloadTable} = useThermostats(
    debouncedSearchTerm,
    queryOptions,
  )
  const totalCount =
    response?.data?.transactionalDb?.allThermostatStatusViews?.totalCount || 0

  const displayTemperature = (value: number | null): string => {
    if (!value) return '—'

    const fahrenheit = celsiusToFahrenheit(value)
    return `${fahrenheit.toFixed(1)}°F`
  }

  useEffect(() => {
    if (devices) {
      settableData(
        devices.map(device => {
          const {
            unitNumber,
            location,
            deviceType,
            ambientTemperatureCelsius,
            heatCelsiusSetting,
            coolCelsiusSetting,
            ambientHumidityPercent,
            lastUpdatedDt,
            mode,
          } = device
          const unit = unitNumber && +unitNumber ? `Unit ${unitNumber}` : unitNumber

          return {
            name: (
              <div className='d-flex align-center'>
                <Icon icon={'thermometr'} theme='info' size='md' />
                <div className='thermostat-id label-small-regular-12 blacks'>
                  {deviceType}
                </div>
              </div>
            ),
            temperature: displayTemperature(ambientTemperatureCelsius),
            location: location || '—',
            unit: unit || '—',
            updatedAt: lastUpdatedDt ? formatDateTime(new Date(lastUpdatedDt)) : '—',
            mode: mode ? capitalize(mode) : '—',
            heat: displayTemperature(heatCelsiusSetting),
            cool: displayTemperature(coolCelsiusSetting),
            humidity: ambientHumidityPercent ? `${ambientHumidityPercent}%` : '—',
          }
        }),
      )
    } else {
      settableData([])
    }
  }, [devices])

  const dataForTableQuery = useCallback(async () => {
    try {
      const query = await queryForDownloadTable()

      const devices = query.data?.transactionalDb?.allThermostatStatusViews.nodes || []

      const tableData = devices.map(
        ({unitNumber, buildingName, location, deviceType}) => {
          const unit = unitNumber && +unitNumber ? `Unit ${unitNumber}` : unitNumber
          const building =
            buildingName && +buildingName ? `Building ${buildingName}` : buildingName

          return Object.values({
            name: deviceType,
            temperature: '-',
            location: location || '—',
            building: building || '—',
            unit: unit || '—',
            battery: '—',
            updatedAt: '—',
          })
        },
      )

      tableData.unshift([
        'Name',
        'Temperature',
        'Location',
        'Building',
        'Unit',
        'Battery Level',
        'Updated Date',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const onSubmitFilter = useCallback(
    (filters: TActivityFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const onTypeSearchField = useCallback(
    (text: string) => {
      upsertQueryOptions({
        page: 1,
        searchTerm: text,
      })
    },
    [upsertQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => upsertQueryOptions(prev => ({...prev, page})),
    [upsertQueryOptions],
  )

  const onChangeSortOrder = (value: string) => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const emptyTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Thermostats table is empty...',
  })

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Thermostats'} data-testid={'ThermostatsView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search locks'
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onTypeSearchField}
                  />
                  <SearchFilters
                    filter={ThermostatsFilter}
                    initialValue={queryOptions.filters}
                    onSubmit={onSubmitFilter}
                  />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>
                <Panel theme={'white'}>
                  <DataGrid
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    order={tableSort.order}
                    emptyTableComponent={emptyTable}
                    columns={[
                      {key: 'name', name: 'Device Name', sortable: true},
                      {key: 'location', name: 'Location', sortable: true},
                      {key: 'temperature', name: 'Temp.', sortable: true},
                      {key: 'mode', name: 'Mode', sortable: true},
                      {key: 'heat', name: 'Heat', sortable: true},
                      {key: 'cool', name: 'Cool', sortable: true},
                      {key: 'humidity', name: 'Humidity', sortable: true},
                      {key: 'unit', name: 'Unit', sortable: true},
                      {key: 'updatedAt', name: 'Updated', sortable: true},
                    ]}
                    rows={tableData}
                    loading={response.loading}
                  />
                </Panel>

                <TableFooter itemCount={totalCount}>
                  <Paginator
                    itemCount={totalCount}
                    perPage={queryOptions.limit}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default Thermostats
