import {FC, useEffect, useMemo, useState} from 'react'
import Badge from '../../../components/Badge'
import Card from '../../../components/Card'
import CardBody from '../../../components/Card/CardBody'
import Row from '../../../components/Grid/Row'
import InputSelect from '../../../components/InputSelect'
import Meter from '../../../components/Meter'
import Spinner from '../../../components/Spinner'
import useLeaseData from './useLeaseData'
import usePropertiesData from '../../../hooks/data/usePropertiesData'

const OccupancyCard: FC = () => {
  const [selectedProperty, setSelectedProperty] = useState('')

  const properties = usePropertiesData()
  const leaseData = useLeaseData({
    propertyIds: selectedProperty ? [Number(selectedProperty)] : undefined,
  })

  const propertiesOptions = useMemo<
    {
      label: string
      value: string
    }[]
  >(() => {
    if (!properties.data) {
      return []
    }

    return properties.data.map(({propertyId, propertyName}) => ({
      value: propertyId.toString(),
      label: propertyName,
    }))
  }, [properties.data])

  useEffect(() => {
    if (propertiesOptions.length) {
      setSelectedProperty(propertiesOptions[0].value)
    }
  }, [propertiesOptions])

  return (
    <Card padding='none'>
      <CardBody>
        {leaseData.loading || properties.loading ? (
          <div className={'d-flex justify-center align-center'}>
            <Spinner />
          </div>
        ) : (
          <>
            <div className={'d-flex space-between align-center occupancy'}>
              <h4 className={'no-margin color-black'}>Occupancy</h4>
              <InputSelect
                allowNull={false}
                value={selectedProperty}
                options={propertiesOptions}
                onValueChange={setSelectedProperty}
              />
            </div>
            <div className={'d-flex justify-center'}>
              <div className={'d-flex space-between align-center metrics-occupancy'}>
                <Row>
                  <Meter value={Math.round(leaseData.counters.ocupancy)} />
                </Row>
                <Row>
                  <div className='text-align-center metrics-occupancy-statistic'>
                    <div>
                      <Badge theme={'info'} size={'lg'}>
                        {leaseData.counters.totalUnitsLeased}
                      </Badge>
                      <p>Total Units Leased</p>
                    </div>
                    <div>
                      <Badge theme={'default'} size={'lg'}>
                        {leaseData.counters.totalUnits}
                      </Badge>
                      <p>Total Units Available</p>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default OccupancyCard
