import {useQuery} from '@apollo/client'
import {useParams} from 'react-router-dom'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {
  TAllBuidlingsVariables,
  TAllBuildingsResponse,
  TAllUnitsOfProperyResponse,
  TAllUnitsVariables,
} from '../../data/graphql/queries/common/types'
import {
  GET_ALL_BUILDING,
  GET_ALL_UNITS_FOR_PROPERTY_DETAILS,
} from '../../data/graphql/queries/common'
import {getInFilter} from '../../functions/filters'
import {QueryOptions} from '../../models'
import {TUnitsFilterFields} from '../../views/PropertyDetails/Units/UnitsFilter'
import useToast from '../useToast'
import {client} from '../../data/graphql'
import {TFilterItem} from '../filters/useStructuresFilter'

const defaultUnits = []

const useUnitsData = (
  searchTerm: string,
  options: Required<QueryOptions<TUnitsFilterFields>>,
) => {
  const {showToast} = useToast()
  const params = useParams<{propertyId: string}>()
  const propertyId = params.propertyId ? +params.propertyId : -1

  const [buildingIds, setBuildingIds] = useState<string[]>([])
  const defaultBuildingIds = buildingIds.map(id => +id)
  const buildingsResponse = useQuery<TAllBuildingsResponse, TAllBuidlingsVariables>(
    GET_ALL_BUILDING,
    {
      variables: {
        condition: {
          isActive: true,
          isDeleted: false,
          propertyId,
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Buildings Data',
          type: 'error',
        })
      },
    },
  )

  const temporaryRemovePropertyFilter = (unitFilters: TFilterItem[]) => {
    return unitFilters.reduce((result: any[], filter) => {
      if (
        Object.keys(filter).length > 1 &&
        defaultBuildingIds.includes(+filter.propertyId.in[0])
      ) {
        const updatedFilter = {...filter}
        delete updatedFilter.propertyId
        result.push(updatedFilter)
      }
      return result
    }, [])
  }

  useEffect(() => {
    const buildings = buildingsResponse.data?.transactionalDb.allBuildings.nodes
    if (buildings) {
      setBuildingIds(
        buildings
          .map(({buildingId}) => buildingId)
          .filter(
            buildingId => +buildingId !== Number(process.env.REACT_APP_DEVS_BUILDING_ID),
          ),
      )
    } else {
      setBuildingIds([])
    }
  }, [buildingsResponse.data?.transactionalDb.allBuildings.nodes])

  const variables = useMemo(() => {
    const {unitFilters, floorplan} = options.filters

    const filter: {[key: string]: any} = {
      and: [],
      or: temporaryRemovePropertyFilter(unitFilters || []),
    }

    if (filter.or.length === 0) {
      filter.buildingId = getInFilter(defaultBuildingIds)
    }

    if (floorplan?.length) {
      filter.and.push({floorPlanId: getInFilter(floorplan)})
    }

    if (searchTerm) {
      filter.unitNumber = {
        includes: searchTerm,
      }
    }

    return {
      filter,
      first: options.limit,
      offset: options.limit * (options.page - 1),
      condition: {
        isDeleted: false,
        isActive: true,
      },
      leasesCondition: {
        isActive: true,
        isDeleted: false,
        isExpired: false,
      },
      ...(options?.orderBy ? {orderBy: options.orderBy} : {}),
    }
  }, [searchTerm, buildingIds, options])

  const response = useQuery<TAllUnitsOfProperyResponse, TAllUnitsVariables>(
    GET_ALL_UNITS_FOR_PROPERTY_DETAILS,
    {
      variables,
      skip: buildingIds.length === 0 || buildingsResponse.loading,
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Units Data',
          type: 'error',
        })
      },
    },
  )

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {...variables}
    if ('first' in copiedVariables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    return await client.query({
      query: GET_ALL_UNITS_FOR_PROPERTY_DETAILS,
      variables: copiedVariables,
    })
  }, [variables])

  return {
    units: response.data?.transactionalDb.allUnits?.nodes || defaultUnits,
    response,
    variables,
    queryForDownloadTable,
  }
}

export default useUnitsData
