import Filters from '../../../components/Filters'
import AccordionGroup from '../../../components/Accordion/AccordionGroup'
import useDateRangeFilter from '../../../hooks/filters/useDateRangeFilter'
import DateRangeFilterNode from '../../../forms/Filters/FilterNodes/DateRangeFilterNode'
import {TFilterComponentProps} from '../../../layouts/People/Search/SearchFilters'
import StructuresFilterNode from '../../../forms/Filters/FilterNodes/StructuresFilterNode'
import useStructuresFilter, {
  TFilterItem,
} from '../../../hooks/filters/useStructuresFilter'

export type TResidentFilterFields = {
  unitFilters?: TFilterItem[]
  moveInEnd?: string
  moveInStart?: string
  moveOutEnd?: string
  moveOutStart?: string
}

const ResidentsFilters = ({
  isOpen,
  initialValue,
  onHide,
  onSubmit,
}: TFilterComponentProps<TResidentFilterFields>) => {
  const {structures, setStructures, getSelectedIds, clearSelections} =
    useStructuresFilter(initialValue?.unitFilters)
  const [moveInRange, setMoveInRange] = useDateRangeFilter({
    to: initialValue?.moveInEnd,
    from: initialValue?.moveInStart,
  })
  const [moveOutRange, setMoveOutRange] = useDateRangeFilter({
    to: initialValue?.moveOutEnd,
    from: initialValue?.moveOutStart,
  })

  const onApplyFilter = () => {
    const unitFilters = getSelectedIds()
    onSubmit?.({
      unitFilters,
      moveInEnd: moveInRange.to || undefined,
      moveInStart: moveInRange.from || undefined,
      moveOutEnd: moveOutRange.to || undefined,
      moveOutStart: moveOutRange.from || undefined,
    })
  }

  const onClearFilter = () => {
    clearSelections()
    setMoveInRange({
      to: '',
      from: '',
    })
    setMoveOutRange({
      to: '',
      from: '',
    })
  }

  return (
    <Filters
      isOpen={isOpen}
      onHide={onHide}
      onClear={onClearFilter}
      onSubmit={onApplyFilter}
    >
      <AccordionGroup>
        <StructuresFilterNode value={structures} onChange={setStructures} />
        <DateRangeFilterNode
          value={moveInRange}
          title={'Move in date'}
          onChange={setMoveInRange}
        />
        <DateRangeFilterNode
          value={moveOutRange}
          title={'Move out date'}
          onChange={setMoveOutRange}
        />
      </AccordionGroup>
    </Filters>
  )
}

export default ResidentsFilters
