import {useQuery} from '@apollo/client'
import {
  TGetAssignedDevicesResponse,
  TGetAssignedDevicesVariables,
} from '../../data/graphql/queries/devices/types'
import {GET_ALL_ASSIGNED_DEVICES} from '../../data/graphql/queries/devices'
import {DeviceTypes} from '../../data/graphql/queries/common/types'
import {useEffect, useState} from 'react'
import {getInventoryId} from '../../functions/lock.functions'
import useInstallerContext from '../../layouts/VendorUserLayout/InstallerContext/useIntallerContext'

type TDeviceData = {
  unitId: string
  installedDeviceId: string | null
  assignedVendorId: string | null
  deviceLocationTypeId: string
  deviceTypeId: string
  deviceClassTypeId: string
  deviceWorkAssignmentId: string | null
}

const useUnavailableDevices = (vendorId?: string | number) => {
  const [devices, setDevices] = useState<Record<string, TDeviceData>>({})
  const {replacements} = useInstallerContext()
  const response = useQuery<TGetAssignedDevicesResponse, TGetAssignedDevicesVariables>(
    GET_ALL_ASSIGNED_DEVICES,
    {
      variables: {
        assignmentsCondition: {
          isActive: true,
          isDeleted: false,
          isComplete: false,
          deviceWorkAssignmentType: 'I',
        },
        assignmentsFilter: {
          deviceTypeId: {
            in: [
              DeviceTypes.YALE_622,
              DeviceTypes.NEST_E,
              DeviceTypes.BRIVO,
              DeviceTypes.YALE_ASSURE_2,
              DeviceTypes.HONEYWELL_T6,
            ],
          },
        },
        devicesFilter: {
          isDeleted: {
            equalTo: false,
          },
          deviceTypeId: {
            in: [
              DeviceTypes.YALE_622,
              DeviceTypes.NEST_E,
              DeviceTypes.BRIVO,
              DeviceTypes.YALE_ASSURE_2,
              DeviceTypes.HONEYWELL_T6,
            ],
          },
        },
      },
      fetchPolicy: 'network-only',
    },
  )

  useEffect(() => {
    const assignments = response?.data?.transactionalDb?.assignments?.nodes || []
    const installedDevices = response?.data?.transactionalDb?.devices?.nodes || []
    const newDevices: Record<string, TDeviceData> = {}
    const devicesAssignedToVendor: string[] = [] // "element is a string of shape deviceClassTypeId:deviceLocationTypeId"

    // do not add to unavailable devices
    // if there is assignemnt for this user
    // for same class type and location type

    assignments.forEach(device => {
      const classTypeId = device.deviceTypeByDeviceTypeId.deviceClassTypeId
      const locationTypeId = device.deviceLocationTypeId
      const deviceInventoryId = getInventoryId(
        device.unitId,
        device.deviceTypeId,
        locationTypeId,
      )

      if (Number(device.assignedVendorId) === Number(vendorId)) {
        devicesAssignedToVendor.push(`${classTypeId}:${locationTypeId}`)
        return
      }

      if (replacements.includes(deviceInventoryId)) {
        return
      }

      newDevices[deviceInventoryId] = {
        unitId: device.unitId,
        deviceTypeId: device.deviceTypeId,
        deviceClassTypeId: classTypeId,
        installedDeviceId: device.installedDeviceId,
        deviceLocationTypeId: locationTypeId,
        assignedVendorId: device.assignedVendorId,
        deviceWorkAssignmentId: device.deviceWorkAssignmentId,
      }
    })

    installedDevices.forEach(device => {
      const classTypeId = device.deviceType.deviceClassTypeId
      const locationTypeId = device.deviceLocationTypeId
      const deviceInventoryId = getInventoryId(
        device.unitId,
        device.deviceType.id,
        locationTypeId,
      )

      if (devicesAssignedToVendor[`${classTypeId}:${locationTypeId}`]) {
        return
      }

      if (replacements.includes(deviceInventoryId)) {
        return
      }

      newDevices[deviceInventoryId] = {
        unitId: device.unitId,
        deviceTypeId: device.deviceType.id,
        deviceClassTypeId: classTypeId,
        installedDeviceId: device.installedDeviceId,
        deviceLocationTypeId: locationTypeId,
        assignedVendorId: device.assignments.nodes[0]?.assignedVendorId || null,
        deviceWorkAssignmentId: device.assignments.nodes[0]?.id || null,
      }
    })

    setDevices(newDevices)
  }, [replacements, response.data])

  return {
    loading: response.loading,
    devicesByInventoryId: devices,
    response,
  }
}

export default useUnavailableDevices
