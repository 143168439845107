import {TAccessPointsValue} from '../layouts/VendorUserLayout/useAccessPoints'
import {isCommonAreaBuilding} from './devices.function'

/** Checks to see if something is an object */
export function isObject(obj): boolean {
  return obj !== null && typeof obj === 'object'
}

/** Returns an array of keys of a single object */
export function getKeys<T>(obj): T[] | string[] {
  return Object.keys(obj)
}

/** Returns value of finded key in object recursive and return */
export function findKeyInObject<T>(obj: any, key: string): T {
  let res: any
  Object.keys(obj).forEach(k => {
    if (k === key) {
      res = obj[k]
    } else if (isObject(obj[k])) {
      res = findKeyInObject(obj[k], key)
    }
  })
  return res
}

export function isObjectEmpty(object: object) {
  return Object.keys(object).length === 0 && object.constructor === Object
}

export const getKeyByValue = (object: object, value: any) => {
  const keys = Object.keys(object)

  return keys.find(key => object[key] === value)
}

export const flattenAccessPoints = <T = any>(accessPoints: TAccessPointsValue<T>) => {
  const result: {[unitId: string]: T} = {}

  Object.keys(accessPoints).forEach(propertyId => {
    const propertyData = accessPoints[propertyId].data
    const propertyBuildings = accessPoints[propertyId].buildings

    Object.keys(propertyBuildings).forEach(buildingId => {
      if (isCommonAreaBuilding(propertyBuildings[buildingId])) {
        return
      }

      const buildingData = propertyBuildings[buildingId].data
      const buildingUnits = propertyBuildings[buildingId].units

      Object.keys(buildingUnits).forEach(unitId => {
        const unitData = buildingUnits[unitId]
        const data = unitData || buildingData || propertyData

        if (data) {
          result[unitId] = data
        }
      })
    })
  })

  return result
}
