import {useLazyQuery, useMutation} from '@apollo/client'
import {GET_PERSON_DEVICE_ACCESSES} from '../data/graphql/queries/people'
import {
  TGetPersonDeviceAccessesResponse,
  TGetPersonDeviceAccessesVariables,
} from '../data/graphql/queries/people/types'
import {
  TGetLockIdsByDeviceIdsResponse,
  TGetLockIdsByDeviceIdsVariables,
} from '../data/graphql/queries/devices/types'
import {GET_LOCK_IDS_BY_INSTALLED_DEVICE_IDS} from '../data/graphql/queries/devices'
import {
  TRevokeAccessToLocksResponse,
  TRevokeAccessToLocksVariables,
  TRevokeCommonAreaAccessResponse,
  TRevokeCommonAreaAccessVariables,
} from '../data/graphql/mutations/lock/types'
import {
  REVOKE_ACCESSES_TO_LOCKS,
  REVOKE_COMMON_AREA_ACCESSES,
} from '../data/graphql/mutations/lock'
import useToast from './useToast'
import {DeviceMaker, isCommonError} from '../data/graphql/types'
import {useCallback} from 'react'
import {AccessTypes, DeviceTypes} from '../data/graphql/queries/common/types'

type Props = {
  unitId: number
  personId: number
  cb?: () => void
}

export const useRevokeGuestUnitLockAcceses = ({unitId, personId, cb}: Props) => {
  const {showToast} = useToast()
  const [getPersonAccesses, personAccessesResponse] = useLazyQuery<
    TGetPersonDeviceAccessesResponse,
    TGetPersonDeviceAccessesVariables
  >(GET_PERSON_DEVICE_ACCESSES, {
    variables: {
      condition: {
        personId,
        isActive: true,
        unitId,
      },
    },
  })

  const [getLocksByDeviceIds, locksResponse] = useLazyQuery<
    TGetLockIdsByDeviceIdsResponse,
    TGetLockIdsByDeviceIdsVariables
  >(GET_LOCK_IDS_BY_INSTALLED_DEVICE_IDS)

  const [revokeLockAccessesRequest, revokeLockAccessesResponse] = useMutation<
    TRevokeAccessToLocksResponse,
    TRevokeAccessToLocksVariables
  >(REVOKE_ACCESSES_TO_LOCKS)

  const [revokeCommonAreaAccessRequest, revokeCommonAreaAccessResponse] = useMutation<
    TRevokeCommonAreaAccessResponse,
    TRevokeCommonAreaAccessVariables
  >(REVOKE_COMMON_AREA_ACCESSES)

  const getYaleIdsByInstalledDeviceIds = useCallback(
    async (installedDeviceIds: number[]): Promise<string[]> => {
      const locks = await getLocksByDeviceIds({
        variables: {
          filter: {
            deviceTypeId: {
              in: [DeviceTypes.YALE_622, DeviceTypes.YALE_ASSURE_2],
            },
            installedDeviceId: {
              in: installedDeviceIds,
            },
          },
        },
      })

      const yaleLockIds =
        locks.data?.transactionalDb?.allDeviceInventoryViews?.nodes?.map(
          device => device.lockId,
        ) || []

      return yaleLockIds
    },
    [getLocksByDeviceIds],
  )

  const getRevokeAccessesData = useCallback(async () => {
    const accessesResponse = await getPersonAccesses()
    const allPersonAccesses =
      accessesResponse.data?.transactionalDb?.allPersonAccesses?.nodes
    const installedDeviceIds =
      allPersonAccesses?.map(access => Number(access.installedDeviceId)) || []
    const yaleUserId =
      allPersonAccesses?.[0]?.personByPersonId?.miscInfo?.yaleLock?.userId || ''
    const brivoUserId =
      allPersonAccesses?.[0]?.personByPersonId?.miscInfo?.brivoLock?.userId || ''
    const propertyId = Number(allPersonAccesses?.[0]?.propertyId)

    const yaleLockIds = await getYaleIdsByInstalledDeviceIds(installedDeviceIds)

    const hasResidentAccess = allPersonAccesses?.some(
      ({personAccessTypeId}) => +personAccessTypeId === AccessTypes.R,
    )

    return {
      brivoUserId,
      yaleUserId,
      yaleLockIds,
      hasResidentAccess,
      propertyId: Number.isNaN(propertyId) ? -1 : propertyId,
    }
  }, [getPersonAccesses, getYaleIdsByInstalledDeviceIds])

  const revokeYaleAccesses = useCallback(
    async (yaleUserId: string, yaleLockIds: string[]) => {
      try {
        const revokeResponse = await revokeLockAccessesRequest({
          variables: {
            input: {
              yaleLockIds,
              yaleUserId,
              deviceMaker: DeviceMaker.YALE,
            },
          },
        })

        const data = revokeResponse.data?.lock.revokeAccessToLocks

        const isRevokeFailed = isCommonError(data) || data?.failed?.length

        if (isRevokeFailed) {
          throw new Error('Failed to revoke access to unit locks')
        }

        showToast({
          title: 'Unit access',
          message: 'Access to the unit has been revoked',
          type: 'error',
        })
      } catch (e) {
        showToast({
          title: 'Unit access',
          message: 'Failed to revoke access to unit locks',
          type: 'error',
        })
      }
    },
    [revokeLockAccessesRequest, showToast],
  )

  const revokeBrivoAccesses = useCallback(
    async (personId: number, propertyId: number) => {
      try {
        const revokeResponse = await revokeCommonAreaAccessRequest({
          variables: {
            input: {
              propertyId,
              personId,
              deviceMaker: DeviceMaker.BRIVO,
            },
          },
        })

        const data = revokeResponse.data?.lock.revokeAccessFromCommonAreaLocks
        const isRevokeFailed = isCommonError(data) || !data?.success

        if (isRevokeFailed) {
          throw new Error('Failed to revoke access to common area locks')
        }

        showToast({
          title: 'Common areas access',
          message: 'Access to common area locks has been revoked',
          type: 'info',
        })
      } catch (e) {
        showToast({
          title: 'Request Error',
          message: 'Failed to revoke user accesses',
          type: 'error',
        })
      }
    },
    [revokeCommonAreaAccessRequest, showToast],
  )

  const revokeLockAccesses = useCallback(async () => {
    try {
      const {yaleUserId, propertyId, yaleLockIds, brivoUserId, hasResidentAccess} =
        await getRevokeAccessesData()

      const revokeAcccesesList = [revokeYaleAccesses(yaleUserId, yaleLockIds)]

      if (hasResidentAccess && brivoUserId) {
        revokeAcccesesList.unshift(revokeBrivoAccesses(Number(personId), propertyId))
      }

      await Promise.allSettled(revokeAcccesesList)
    } catch (e) {
      showToast({
        title: 'Request Error',
        message: 'Failed to revoke user accesses',
        type: 'error',
      })
    }
  }, [
    getRevokeAccessesData,
    personId,
    revokeBrivoAccesses,
    revokeYaleAccesses,
    showToast,
  ])

  return {
    revokeLockAccesses,
    loading:
      locksResponse.loading ||
      personAccessesResponse.loading ||
      revokeCommonAreaAccessResponse.loading ||
      revokeLockAccessesResponse.loading,
  }
}
