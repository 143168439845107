import {FC} from 'react'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import {useDevicesStatus} from '../../../hooks/data/useDevicesStatus'
import {useNavigate} from 'react-router-dom'
import useGuestsCount from './useGuestsCount'
import useLeaseData from './useLeaseData'
import MetricCounter from './CounterCard'
import RentOverdueCard from './RentOverdue'
import OccupancyCard from './OccupancyCard'
import useUserAccess from '../../../hooks/useUserAccess'

const Metrics: FC = () => {
  const {properties} = useUserAccess()
  const navigate = useNavigate()
  const guests = useGuestsCount()
  const devices = useDevicesStatus()
  const leaseData = useLeaseData({propertyIds: properties})

  const openOfflineDevices = () => {
    navigate(
      '/devices/locks?limit=10&page=1&orderBy=DEVICE_STATUS_CURRENT_ASC&searchTerm=',
    )
  }

  const openLowbatteryDevices = () => {
    navigate(
      '/devices/locks?limit=10&page=1&orderBy=DEVICE_BATTERY_LEVEL_ASC&searchTerm=',
    )
  }

  return (
    <Section>
      <Row>
        <Column lg={'6'}>
          <Row>
            <Column sm={'12'}>
              <RentOverdueCard
                loading={leaseData.loading}
                value={leaseData.counters.overdueRent}
              />
            </Column>

            <Column sm={'6'}>
              <MetricCounter
                loading={devices.statusQuery.loading}
                iconName='wifi-off'
                value={devices.counters.locksOffline}
                label={'Offline'}
                onClick={openOfflineDevices}
              />

              <MetricCounter
                loading={devices.statusQuery.loading}
                iconName='clipboard'
                value={devices.counters.locksLowBattery}
                label={'Low Battery'}
                onClick={openLowbatteryDevices}
              />
            </Column>

            <Column sm={'6'}>
              <MetricCounter
                loading={devices.statusQuery.loading}
                iconName='map-pin'
                value={'#'}
                label={'Jammed Locks'}
              />

              <MetricCounter
                loading={guests.loading}
                iconName='user-plus'
                value={guests.total}
                label={'My Guests'}
              />
            </Column>
          </Row>
        </Column>

        <Column lg={'6'}>
          <OccupancyCard />
        </Column>
      </Row>
    </Section>
  )
}

export default Metrics
