import React from 'react'
import Accordion from '../../../components/Accordion'
import AccordionBody from '../../../components/Accordion/AccordionBody'
import AccordionHead from '../../../components/Accordion/AccordionHead'
import InputRadioGroup from '../../../components/InputRadioGroup'
import {RadioOption} from '../../../components/InputRadioGroup/InputRadioGroup'

export type TBuildingsFilterProps = {
  title?: string
  value: string
  options: RadioOption[]
  onChange: (value: string) => void
}

const RadioButtonFilterNode = ({
  title,
  value,
  options,
  onChange,
}: TBuildingsFilterProps) => {
  return (
    <Accordion transparent>
      <AccordionHead>
        <div className='d-flex space-between align-center'>
          <div className='desktop-label-semibold-14'>{title}</div>
        </div>
      </AccordionHead>

      <AccordionBody>
        <InputRadioGroup
          groupName='RadioButtonFilterNode'
          value={value}
          options={options}
          onRadioChange={onChange}
        />
      </AccordionBody>
    </Accordion>
  )
}

export default React.memo(RadioButtonFilterNode)
