import './Staff.style.scss'

import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Button from '../../../components/Button'
import DataGrid from '../../../components/DataGrid'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import Paginator from '../../../components/Paginator'
import Panel from '../../../components/Panel'

import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import Search from '../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import useStaffData from '../../../hooks/data/useStaffData'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import {capitalize, toCommonDateFormat} from '../../../functions'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import useTableSort from '../../../hooks/useTableSort'
import DeactivatePersonCell from '../../../components/DeactivatePersonCell'

interface StaffRow {
  userName: string
  role: string
  startDate: React.ReactNode
  id: number
}

const PAGE_SIZE = 10

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'userName:asc', value: 'EMPLOYEE_NAME_ASC', label: 'Name Acs'},
  {sortKey: 'userName:desc', value: 'EMPLOYEE_NAME_DESC', label: 'Name Desc'},
  {sortKey: 'role:asc', value: 'DEPARTMENT_DESCRIPTION_ASC', label: 'Role Acs'},
  {sortKey: 'role:desc', value: 'DEPARTMENT_DESCRIPTION_DESC', label: 'Role Desc'},
  {sortKey: 'startDate:asc', value: 'HIRE_DATE_ASC', label: 'Hire Date Asc'},
  {sortKey: 'startDate:desc', value: 'HIRE_DATE_DESC', label: 'Hire Date Desc'},
]

const Staff = () => {
  const navigate = useNavigate()
  const params = useParams<{propertyId: string}>()
  const {setQuery} = useContext(ExportTableContext)
  const propertyId = params.propertyId ? +params.propertyId : -1

  const [staff, setStaff] = useState<StaffRow[]>([])

  const {queryOptions, upsertQueryOptions, debouncedSearchTerm, onChangeNumberOfItems} =
    useQueryOptions({
      limit: 10,
      page: 1,
      orderBy: ['HIRE_DATE_DESC'],
      searchTerm: '',
    })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    staff: staffList,
    response,
    queryForDownloadTable,
  } = useStaffData(debouncedSearchTerm, queryOptions, {
    propertyId,
  })

  useEffect(() => {
    if (staffList) {
      setStaff(
        staffList?.map(item => ({
          userName: capitalize(item.employeeName || ''),
          id: item.personEmployeeId as number,
          role: item.departmentDescription || '—',
          startDate: (
            <DeactivatePersonCell
              personId={Number(item.personId)}
              onRevoke={response.refetch}
            >
              {toCommonDateFormat(item.hireDate) || '—'}
            </DeactivatePersonCell>
          ),
        })),
      )
    } else {
      setStaff([])
    }
  }, [staffList])

  const onTypeSearchField = useCallback(
    (text: string) => {
      upsertQueryOptions({
        page: 1,
        searchTerm: text,
      })
    },
    [upsertQueryOptions],
  )

  const onPressRow = useCallback(
    (index: number) => {
      const staffId = staff.slice(0, 10)[index].id
      navigate(`/properties/staff/${propertyId}/${staffId}`)
    },
    [staff, navigate, propertyId],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [upsertQueryOptions],
  )

  const dataForTableQuery = useCallback(async () => {
    try {
      const query = await queryForDownloadTable()

      const staff = query.data?.transactionalDb.allPortalEmployeeViews.nodes

      const tableData = staff.map(staff => {
        const name = capitalize(staff.employeeName || '-')
        return Object.values({
          name: name,
          role: staff.departmentDescription || '—',
          hireDate: toCommonDateFormat(staff.hireDate) || '—',
        })
      })

      tableData.unshift(['Name', 'Role', 'Hire Date'])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Staff'} data-testid={'StaffView'}>
        <Section id={'property-staff-container'}>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search staff'
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onTypeSearchField}
                  />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={() => undefined}
                  />
                </Search>
                <Panel theme={'white'}>
                  <DataGrid
                    selectableRows
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    order={tableSort.order}
                    onRowSelect={onPressRow}
                    loading={false}
                    columns={[
                      {name: 'Name', key: 'userName', sortable: true},
                      {name: 'Role', key: 'role', sortable: true},
                      {name: 'Hire Date', key: 'startDate', sortable: true},
                    ]}
                    rows={staff.slice(0, 10)}
                  />
                </Panel>
                <TableFooter
                  itemCount={
                    response.data?.transactionalDb.allPortalEmployeeViews?.totalCount || 0
                  }
                >
                  <Paginator
                    itemCount={
                      response.data?.transactionalDb.allPortalEmployeeViews?.totalCount ||
                      0
                    }
                    perPage={PAGE_SIZE}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default Staff
