import {normalize} from './string.functions'

/**
 * validate time string
 * @param {string} time - time in string format
 * @return {boolean} - is time string valid
 */
export function ValidateTime(time: string): boolean {
  const regex = new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')
  return regex.test(time)
}

/**
 * validate one email address
 * @param {string} email - email of users
 * @return {boolean} - is email valid
 */
export function validateEmail(email: string): boolean {
  // eslint-disable-next-line max-len
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const validatePasword = (password: string, minLength = 8) => {
  const hasCorrectLength = password.length >= minLength
  const hasLowerCase = /[a-z]/g.test(password)
  const hasUpperCase = /[A-Z]/g.test(password)
  const hasSpecialChar = /[0-9]/g.test(password)
  const hasNumber = /[#@$?_!]/g.test(password)
  const hasNoSpaces = /^\S*$/g.test(password)

  return (
    hasCorrectLength &&
    hasLowerCase &&
    hasUpperCase &&
    hasSpecialChar &&
    hasNumber &&
    hasNoSpaces
  )
}

export const isValidPhoneNumber = (value?: string) => {
  if (!value) {
    return false
  }

  const phoneRegExp = /^\+1\d{10}$/
  return phoneRegExp.test(value)
}

export const formatIdentityError = (error: string) => {
  const message = error.split('/')[1].split('-').join(' ')
  const formattedError = message.charAt(0).toUpperCase() + message.slice(1) + '!'

  return formattedError
}
